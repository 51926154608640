import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} ganancia por cada M',
      accountEarning: 'ganancia de cuenta',
      active: 'activo',
      activeMiner: 'minero activa',
      amoutPaid: 'pagado',
      announcement: 'notificación',
      cannotConnect: 'no se puede conectar?',
      urlConfigGpu: ' admite minero de tarjeta gráfica común',
      urlConfigNicehash: ' optimizado especialmente para Nicehash',
      urlConfigGpuDcr:
        'conocido por admitir minero de hormiga DR3, Claymore, gominer',
      urlConfigNicehashDcr:
        ' conocido por admitir máquinas de minería como Nicehash, INNOSILICON, etc',
      customerTitle: 'servicio posventa',
      dailyHashrate: 'hashrate diaria',
      dashboard: 'interfaz',
      earnMore: 'Cuánto más gana el pool de minería de CloverPool que otros',
      earning: '{coinType} ganancia',
      eventConnected: 'ya conectado',
      eventDisconnected: 'desconectado',
      eventMiner: 'minero',
      eventName: 'mensajes',
      eventSkip: 'ya saltado',
      eventTitle: 'notificación de eventos',
      eventlatestLogs: 'abajo se muestran mensajes históricos',
      hastrateChart: 'gráfica de hashrate',
      history: 'historial de pago',
      hour: '{n} hora',
      hours: 'horas',
      inActive: 'no activo',
      lastPaymentTime: 'fecha y hora del último pago',
      markAllRead: 'marcar todo leído',
      minerContent:
        'Ingrese a la interfaz de configuración del software de minería, rellene su subcuenta (se recomienda dejar la contraseña en blanco) y la dirección de minería, guarde y ejecute el programa exe, y la minero se agregará automáticamente a la página del sitio web del pool de minería en un minuto.',
      minerNameStand:
        'La regla de nombramiento de las máquinas de minería es: subcuenta+punto de inglés+número. Por ejemplo, si su subcuenta es {sub_account}, puede establecer el nombre de la minero como {sub_account}.001, {sub_account}.002, etc. Un nombre corresponde a una minero.',
      minerName:
        'composición de nombre de minero para su referencia："{sub_account}.001", "{sub_account}.002".',
      miner: 'minero',
      miners: 'número de máquinas de minería',
      miner1: 'Minero de tipo I',
      miner2: 'Minero tipo II',
      miningAddress: 'dirección de minería',
      miningEarnings: '{coinType} ganancia de hashrate',
      minute: 'minutos',
      more: 'más',
      networkDiff: 'dificultad actual',
      networkHashrate: 'hashrate de la red entera',
      networkStatus: 'estado de la red',
      nextDifficult: 'predecir dificultad de la próxima vez',
      noMiner:
        'Usted necesita agregar minero al pool para empezar a excavar. Se recomienda usar pool de minería de CloverPool',
      btcTools: 'software de proxy。',
      noEvent: 'no hay notificación de evento en este momento!',
      nowHashrate: 'hashrate actual',
      pendingPayouts: 'pagos pendientes por confirmar',
      poolHashrate: 'hashrate del pool de minería',
      realHashrate: '{  coinType} hashrate de tiempo real',
      realtime: 'tiempo real',
      timeRemain: 'tiempo restante que queda hasta el reajuste',
      title: 'interfaz - CloverPool',
      today: 'estimación de hoy',
      unpaid: 'No pagado',
      yesterday: 'monto recibido ayer',
      yesterdayEarnTip:
        'Se refiere a las ganancias de 0: 00-24: 00 el día anterior a la hora UTC.',
      todayEarnTip: 'SHA256 hashrate, estimación de ganancia de minería de BTC',
      charts: {
        hashrate: 'hashrate',
        minerCharts: 'curva de hashrate de minero',
        myCharts: 'mi curva de hashrate',
        poolCharts: 'curva de hashrate de pool de minería',
        reject: 'tasa de rechazo',
        time: 'tiempo',
      },
      yesterday_earn: 'ganancia de ayer',
      yesterday_hashhrate: 'hashrate efectiva de ayer (excluidos rechazos)',
      earnings_per_100T: 'ganacia por cada 100T',
      //declaración de responsabilidad
      statement:
        '* Usted no puede usar (o intentar usar) este servicio de ninguna otra manera que no sea proporcionada o sugerida por nosotros, y debe asegurar expresamente que no usará (o intentará usar) este servicio de ninguna manera ilegal o no legal, o a propósito de asistir ninguna actividad legal o no legal. Al ingresar a este sitio web y registrarse para este servicio, usted promete y asegurar que usted puede obtener legalmente este servicio de acuerdo con las leyes de su jurisdicción.',
      // estimación de ganancia
      estimatedEarnings: {
        title: 'estimación de ganacia de hashrate',
        hours24Earnings: 'estimación de las últimas 24 horas',
        tip24:
          'calcucar según la dificultad de minería promedia del moneda en las últimas 24 horas',
        realTimeEarnings: 'estimación en tiempo real',
        realTimeEarningsTip:
          'calcular según la dificultad de minería más reciente del moneda',
      },
    },
    subAccount: {
      title: 'crear subcuenta - CloverPool',
      createWorker: 'crear subcuenta',
      noNode: 'todavía no ha seleccionado el nodo!',
      noCoinType: 'todavía no ha seleccionado el moneda!',
      selectRegion: 'seleccionar la región',
      setWorker: 'configurar nombre de la subcuenta',
      enterWorker: 'ingresar nombre de la subcuenta',
      enterAddress: 'ingresar dirección de recibo',
      coinTypeEx:
        'CloverPool ya admite minería de {supportCoins}, más tipos de moneda estarán disponibles pronto, quede a la espera.',
      regionEx:
        'CloverPool cuenta con servidores en todo el mundo. Acceder el servidor más cercano permite la mejor experiencia',
      workerEx:
        'La subcuenta que usted configuró se vinculará a la región seleccionada. Usted puede configurar al máximo 2000 subcuentas. Nombre de la minero (worker name) = nombre de la subcuenta + código de la minero, por ejemplo: "{name}.001"，"{name}.002".',
      workerCaution:
        '* Atención, una subcuenta no admite minería inter-regional. La subcuenta que usted creó en la region actual no admite minería en otras regiones. Por favor cree una subcuenta en la región correspondiente.',
      save: 'crear y excavar',
      verifyWorker:
        'subcuenta sólo admite combinación de 3-20 letras o números.',
      option: 'opcional',
      'optional-BTC':
        'Nota: dirección de Bitcoin Cash (BCH) de formato antiguo coincide con la dirección de Bitcoin (BTC). Verifíquela para evitar pérdida.',
      'optional-BCH':
        'Nota: dirección de Bitcoin Cash (BCH) de formato antiguo coincide con la dirección de Bitcoin (BTC). Verifíquela para evitar pérdida. Se recomienda usar dirección de nuevo formato',
      'optional-UBTC':
        'Nota：dirección de Bitcoin Cash (BCH) de formato antiguo es similar a la dirección de UnitedBitcoin (UBTC)，Verifíquela para evitar pérdida.',
      'optional-SBTC':
        'Nota：dirección de Bitcoin Cash (BCH) de formato antiguo es similar a la dirección de SuperBitcoin (SBTC)，Verifíquela para evitar pérdida.',
      'optional-coin':
        'Nota: rellene la dirección de { coin_type } con cuidado para evitar pérdida',
      selectCoinType: 'seleccionar moneda',
      automatic: 'generación automática',
      recommandWallet: 'se recomienda usar cartera de CloverPool',
      withdrawToExchange: 'retiro a la bolsa',
      withdrawToWallet: 'retiro a la cartera',
    },
    tools: {
      // title: 'herramientas - CloverPool',
      // tools: 'herramientas',
      // fppsPps: 'FPPS y herramienta de comparación dinámica tradicional de PPS',
      // aboutFpps: 'ventajas de FPPS en comparación con PPS tradicional？',
      // charts: 'datos dinámicos fáciles de ver y entender',
      // watchCharts: 'haga clic para comparar',
      // miniCalc: 'calculador de minería',
      // miniCalcExpress: 'calcula ganancia de minería mediante hashrate, consumo de energía y gasto electricidad, combinando dificultad y precio de moneda',
      // miniMiniCalc: 'mini calculador de minería',
      // miniMiniCalcExpress: 'calculación rápida de ganancia de minería tomando en cuenta solamente hashrate, dificultad y precio de moneda',
      // strat: 'empezar',
      // btcTool: 'CloverPool herramientas a granel de pool de minería',
      // btcToolIntroduce: 'configurar a granel las máquinas de minería es fácil y conveniente',
      // download: 'haga clic para descargar'
      btcSmartAgent: 'BTC proxy inteligente',
      agentExpress: 'eficiente y transparente <br/> minero individual visible',
      agentExpressBanner:
        'eficiente y transparente <i style="margin-right:20px"></i> minero individual visible',
      bit: 'bit',
      document: 'documento de asistencia de versión windows',
      poolApp: 'CloverPool App de pool de minería, App toda-función',
      poolRealTime: 'ver pool y datos mineros en tiempo real',
      poolOpeation: 'admite funciones como borrar y modificar minero',
      poolComming: 'alerta por notificación push ya disponible',
      poolDownlaod: 'haga cliz para descargar',
      title: 'herramientas - CloverPool',
      tools: 'herramientas',
      fppsPps: 'FPPS y herramienta de comparación dinámica tradicional de PPS',
      aboutFpps: 'ventajas de FPPS en comparación con PPS tradicional？',
      charts: 'datos dinámicos fáciles de ver y entender',
      watchCharts: 'haga clic para comparar',
      miniCalc: 'calculador de minería',
      miniCalcExpress:
        'calcula ganancia de minería mediante hashrate, consumo de energía y gasto electricidad, combinando dificultad y precio de moneda',
      miniMiniCalc: 'mini calculador de minería',
      miniMiniCalcExpress:
        'calculación rápida de ganancia de minería tomando en cuenta solamente hashrate, dificultad y precio de moneda',
      start: 'empezar',
      btcTool: 'CloverPool Herramienta BTC',
      btcToolIntroduce:
        'configurar a granel las máquinas de minería es fácil y conveniente',
      download: 'haga clic para descargar',
      ipToolsTitle: 'herramienta minero masivo de IP de minero de CloverPool',
      ipToolsDes:
        'admite toda serie de máquinas de minería hormiga, modificación de IP ocupada, configuración vinculada directamente al pool de minería',
    },
    miners: {
      createGroup: 'crear nuevo grupo',
      deleteGroupTitle: 'borrar grupo',
      editGroupTitle: 'editar grupo',
      all: 'todo',
      default: 'sin grupo',
      addGroup: 'crear nuevo grupo',
      addGroupInfo:
        'atención, minero seleccionada se agregará automáticamente al nuevo grupo',
      groupNameLabel: 'nombre de grupo',
      addGroupEmpty: 'nombre de grupo es obligatorio',
      groupRejected: 'tasa de rechazao de tiempo real', // Real time rejection rate
      groupNameLimit: 'nombre de grupo debe consistir en 3-20 caracteres',
      inactive: 'inactivo',
      active: 'activo',
      dead: 'inválido',
      moveTo: 'mover a',
      remove: 'borrar',

      allTooltip:
        'incluye: máquinas de minería activas ({active}) + máquinas de minería inactivas（{inactive}）',
      inactiveTooltip:
        'número de máquinas que no han entregado resultado de calculación dentro de diez minutos (Share)',
      activeTooltip:
        'número de máquinas que entregan resultado de calculación normalmente (Share)',
      deadTooltip:
        'número de máquinas que no han entregado resultado de calculación dentro de 24 horas (Share)',
      home: 'portal',
      miners: 'minero',

      workerName: 'nombre de minero',
      Hashrate: 'hashrate de tiempo real',
      Rejected: 'tasa rechazo tiempo real',
      dailyHashrate: 'hashrate diaria',
      firstShare: 'hora primera conexión',
      LastShare: 'fecha y hora de la última entrega',
      groupName: 'nombre de grupo',

      delMillTitle: 'está seguro de borrar esta información de minero？',
      delMillContext:
        'Esta operación eliminará todos los mineros que ha seleccionado, opere con cuidado.',
      delMillFailed: 'borrado fracasado',

      delGroupTitle: 'está seguro de borrar grupo “{name}”？',
      delGroupContext:
        'todas las máquinas de minería del grupo se moverán a sin grupo',

      moveTitle: 'mover minero',
      notChecked:
        'seleccione máquinas de minería por favor, se permite operación a granel',
      moveContext:
        'este operación moverá todas las máquinas seleccionadas a grupo {groupName} ',
      moveFaild: 'no se puede mover al grupo actual',

      searchPlaceholder: 'ingresar nombre de minero',
      reportTime: 'fecha y hora de generación de este informe',
      refresh: 'actualizar',
      editGroupName: 'modificar nombre de grupo',
      workListEmpty: 'no se detectó minero en este grupo',
      exist: 'este grupo ya existe',
      goOldVersion: 'regresar a versión antigua',
      dataExport: `“exportar hashrate de grupo{name}”`,
      exportTip:
        'Seleccione el periodo del que necesita exportar datos, este periodo no puede sobrepasar de 30 días. Formato del archivo exportado es CSV.',
      exportTip2:
        'el periodo de hashrate en cuestión es 00:00 - 23:59，del que el periodo de hoy es 00:00 - hora de exportación',
    },
    minerInfo: {
      miners: 'minero',
      hashrate: 'hashrate individual',
      runningCondition: 'estado de operación',
      min: 'minuto',
      hour: '{n}hora',
      hashrateAxis: 'hashrate',
      rejected: 'tasa de rechazo',
      status: 'estado',
      lastShare: 'fecha y hora de última entrega',
      lastShareIP: 'IP de última entrega',
      hashrateChart: 'gráfica de hashrate',
      realTime: 'tiempo real',
      trans: {
        seconds: 'secundo',
        mins: 'minuto',
        hours: 'hora',
        ago: 'hace',
        ACTIVE: 'activo',
        INACTIVE: 'inactivo',
        DEAD: 'inválido',
      },
    },
    earningHistory: {
      title: 'ganancias históricas - CloverPool',
      earn: 'Ganancias mineras',
      Unpaid: 'No pagado',
      EarningsToday: 'Minado estimado',
      EarningsTodayTip:
        'Esto se refiere a la cantidad estimada de ganancias de PPS que se ha extraído desde las 0:00 (UTC) hasta este momento, y esta cantidad puede desviarse de sus ganancias reales.',
      EarningsYesterday: 'ganancia de ayer',
      Time: 'Fecha de minería',
      NetworkDifficulty: 'Dificultad',
      Earnings: 'ganancia',
      Payment: 'fecha y hora del pago',
      payWithOTCAmount: 'pagar la ganacia:{amount} con{otcType}',
      Mode: 'modo',
      Address: 'Dirección',
      PaidAmount: 'Ganancias',
      yesterday:
        'Se refiere a las ganancias de 0: 00-24: 00 el día anterior a la hora UTC.',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          'no se ha configurado dirección de recibo, se depositará en saldo',
        PENDING_CHANGED_ADDRESS:
          'Tras cambiar la dirección, el pago comenzará dentro de 48 horas. Alerta: si usted no cambió la dirección, es posible que su cuenta (correo electrónico/móvil) se haya visto comprometida. Utilice otra dirección de correo electrónico o número de móvil para registrar una nueva cuenta de inmediato y mueva la minero a la nueva cuenta.',
        PENDING_NOT_ENOUGH:
          'el monto es menos que el pago mínimo que usted configuró (predeterminado :limit), se depositará en saldo',
        PENDING_POOL_SETTLE_FAULT:
          'Por razón del pool de minería, este pago se ha emitido por error, y no se emitirá en adelante',
      },
      depositeAmount: 'depositar en saldo',
      diffChg: 'cambio de dificultad: ',
      equalsPps: 'equivale a PPS',
      Chg: 'cambio diario',
      status: 'Estado de pago',
      paid: 'ya pagado',
      waitingPay: 'a pagar',
      reason: 'razón',
      fppsMoreEarn: '{mode} ganancia extra:',
      fppsContain:
        'En comparación con PPS, la ganancia del modelo {mode} aumentó en aproximadamente un {pps96}',
      noEarningsInformation: 'todavía no información sobre ganancia',
      earningDetail: 'Detalles de ganancias',
      Equivalent: 'monto equivalente',
      extraProfits: 'ganancia extra',
      autoSwap: 'pago con cambio instantáneo',
      original: 'pago cpm moneda orignial',
      Swapped: 'ya cambiado',
      swapping: 'cambio en curso',
      otcEarningTip:
        'La ganancia en la moneda objetivo a pagar con cambio instantáneo bajo el modo piscina inteligente se calcula en función de la tasa de cambio real de cambio instantáneo en el día de facturación de Matrixport.',
      originEarningTip:
        'La ganancia en la moneda objetivo a pagar con cambio instantáneo bajo el modo piscina inteligente se calcula en base al tipo de cambio instantáneo de Matrixport de 8:00 a.m. en el día de facturación',
      exportEarningsData: 'exportar datos de ganancia',
      exportTip:
        'Puede admitir hasta 180 días de datos y el formato de archivo exportado es XLS.',
      originStatusTip:
        'La ganancia se ha convertido en la moneda objetivo mediante la función de cambio instantáneo. Para obtener más información, consulte la página de ganancia en el modo piscina inteligente.',
      swappingTip:
        'se está cambiando la ganancia actual, o la ganancia está demasiado baja para alcanzar el monto mínimo de cambio',
      swappedTip:
        'La ganancia se ha convertido en la moneda objetivo mediante la función de cambio instantáneo. Para obtener más información, consulte la página de ganancia en el modo piscina inteligente.',
      // upgrade TODO 收益改版新增
      settlementHashrate: 'Hashrate de asentamiento',
      addressRatio: 'Proporción de direcciones',
      addressEarnings: 'Ingresos de dirección',
      paymentTime: 'Tiempo de pago',
      TimeTip:
        'La fecha de extracción está en hora UTC. Por ejemplo, el 1 de enero de 2020 se refiere a las 00:00:00 del 1 de enero de 2020-00: 00: 00 del 2 de enero de 2020 en la hora UTC.',
      EarningsTip:
        'La cantidad de ganancias es calculada por el "Centro de liquidación de CloverPool" en base a múltiples factores: el método de distribución de ganancias, la dificultad de la red, el hashrate de liquidación, la recompensa base y la recompensa de tarifa de Tx de Block, y las tasas de comisión, etc. puede haber desviaciones en las ganancias teóricas que calculó en la calculadora de ganancias.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Ganancias por comisiones',
      inviteWorkerAccount: 'Subcuenta de invitado',
      inviteRebateRatio: 'Ratio de comisión',
      inviteRebateAmount: 'Monto de la comisión',
      rebateExportTip:
        'Puede admitir hasta 60 días de datos y el formato de archivo exportado es XLS.',
    },
    poolStats: {
      title: 'estadística de pool de minería - CloverPool',
      poolStats: 'estadística',
      hashrate: 'hashrate',
      coinType: 'moneda',
      miners: 'minero',
      found: 'ganancia por minería',
      hashrateChart: 'gráfica de hashrate',
      blocksRelayed: 'bloques de comunicación',
      height: 'altura',
      time: 'tiempo',
      blocks: 'bloques',
      blockHash: 'hash de bloques',
      ddaScore: 'DAA Score',
      reward: 'premio de bloque',
      day: 'día',
      region: 'región de descubrimiento de nuevo bloque',
      lucky: 'índice de suerte',
      btc: 'bitcoin',
      ltc: 'litecoin',
      nmc: 'namecoin',
      zec: 'zerocoin',
      bcc: 'bitcoin cash',
      bch: 'bitcoin cash',
      eth: 'Ethereum',
      etc: 'ethereum classic',
      dcr: 'DCR',
      doge: 'dogecoin',
      grin: 'GRIN',
      sbtc: 'superbitcoin',
      ubtc: 'unitedbitcoin',
    },

    // guardián
    guardian: {
      expiredDeadline: '(:day borrado automático)',
      guardianRemark:
        'Puede compartir toda o parte de la información de la subcuenta (incluida la hashrate, la minero, etc.) con otros usuarios, quienes solo pueden ver sin poder modificar ninguna información.',
      createGuardian: 'agregar usuario de uso compartido',
      remark: 'nota：',
      user: 'usuario:',
      manage: 'administración',
      status: 'estado:',
      theGuardian: 'cuenta de uso compartido:',
      creationDate: 'fecha de creación:',
      datePrevious: 'fecha de última utilización:',
      bounded: 'vinculada',
      delete: 'borrar',
      deleteGuardianLink: 'borrar enlace de cuenta de usp compartido',
      createGuardianInvitation:
        'crear enlace de invitación de cuenta de uso compartido',
      guardianAccountManagement: 'administración de cuenta de uso compartido',
      modifyRemark: 'modificar comentario',
      validateRemarkEmpty: 'comentario es obligatorio',
      enterRemark: 'ingresar comentario',
      noteInformation:
        'recordatorio: este nombre se usa para distinguir diferentes cuentas de uso compartido, se recomienda usar el contacto u otra información de la otra persona',
      confirm: 'confirmar',
      create: 'crear',
      cancel: 'cancelar',
      guardianSuccessfullyCreated:
        'se ha creado enlace de invitación de cuenta de uso compartido',
      copyLink: 'copiar enlace',
      authorized:
        'tra ser borrada, la otra persona perderá acceso de administración a la subcuentade usted',
      noteSendGuardian:
        'recordatorio: Envíe este enlace a una persona de fiar que necesite administrar máquinas de minería. Este vínculo es sólo para una visita. Si la otra persona le indica que ya no es válido, vuelva a crearlo.',
      close: 'cerrar',
      subaccount: 'subcuenta',
      coinType: 'moneda',
      region: 'región',
      guardianStatus: 'estado de uso compartido',
      guardianSelectExpress:
        'A través del botón en el estado de uso compartido, usted puede elegir libremente la subcuenta que otros pueden administrar (la configuración predeterminada es que todas las subcuentas son accesibles)',
      invitTitle: 'compartir con otros',
      acceptTitle: 'aceptar uso compartido por otros',
      losePower:
        'tras el borrado, usted perderá acceso de administración a la subcuenta de la otra persona.',
      show: 'mostrar',
    },
    //vincular enlace de invitación de guardián
    guardianBind: {
      bindingGuardianInvitation:
        'vincular enlace de invitación de cuenta de uso compartido',
      advance: 'avanzado',
      guardianAccountManagement: 'administración de cuenta de uso compartido:',
      confirmGuardianBind: 'confirmar guardián y vincular cuenta',
      remark: 'comentario',
      enterRemark: 'ingresar comentario',
      suggestName:
        'Se recomienda usar el nombre de la otra persona para facilitar administración conjunta. Si lo deja en blanco, se usará de manera predeterminada el número de móvil o correo electrónico de la otra persona',
      guardian: 'vincular',
      cancel: 'cancelar',
      confirm: 'confirmar',
      manageView: 'administrar y mantener todas las subcuentas de usted？',
      suggestUsingName:
        'Se recomienda usar el nombre de la otra persona para facilitar administración conjunta. Si lo deja en blanco, se usará de manera predeterminada el número de móvil o correo electrónico de la otra persona',
      sms: 'móvil',
      mail: 'correo electrónico',
      code: '{mode} código de verificación',
      send: 'obtener',
    },
    account: {
      subAccountManage: 'Gestión de subcuentas',
      currentAccount: 'cuenta actual',
      locationRegion:
        'Usted se encuentra actualmente en servidor <strong>{coin}</strong> <strong>{region}</strong>',
      locationEx:
        'Cada subcuenta corresponde a una moneda y una región fija. Una vez creadas, la moneda y la región no se pueden modificar. Si tiene otras necesidades, puede crear una nueva subcuenta.',
      create: 'crear',
      general: 'Versión general',
      advance: 'Versión avanzada',
      algorithm: ' algoritmo',
      oneButtonSwitch: 'cambiar por un botón',
      addOthersCoins: 'agregar monedas de otro algoritmo',
      supportCoins: 'admite',
      hiddenSubAccounts: 'ocultar administración de subcuenta',
      hashAlert: 'funcionamiento normal',
      viewHideSubAccount: 'ver subcuentas ocultadas',
      addSubAccount: 'agregar subcuenta',
      recover: 'recuperar',
      latestAlert: {
        hashrate: 'hashrate real {symbol} {expect} {unit}',
        miners: 'número real de máquinas de minería {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'subcuenta',
      currency: 'moneda',
      region: 'región',
      walletAddressAndMinimumPayment: 'dirección de recibo & pago mínimo',
      walletAddress: 'dirección de recibo',
      shareData: 'cuenta de uso <br/>compartido',
      shareDataTip:
        'Puede compartir toda o parte de la información de su subcuenta con otras personas que solo pueden verla pero no modificarla; también puede recibir toda o parte de la información de la subcuenta de otras personas en modo de solo lectura.',
      alertSettings: 'Establecer alertas',
      mywatcher: 'Establecer vigilante',
      apikey: 'API KEY',
      payWithBtc: 'pagar con BTC',
      //wallet address & minimum payment
      setAddress: 'configurar dirección de recibo de {coinType}',
      address: 'dirección de recibo',
      createAddress: 'crear dirección',
      modifyAddress: 'modificar dirección',
      modify: 'modifcar',
      minPay: 'pago mínimo',
      lastUpdateTime: 'fecha y hora de modificación de dirección',
      updateBitconAddressTip:
        'Nota: Para la seguridad de los fondos, el pago se suspenderá dentro de las 48 horas posteriores a esta operación, y sus ganancias se utilizarán como saldo y se pagarán el primer día de liquidación después de 48 horas.',

      old: 'dirección antigua',
      new: 'dirección nueva',
      continue: 'próximo paso',
      addAddress: 'rellenar',
      enterCode: 'rellene el código de verificación por favor',
      newBit: 'su dirección nueva',
      way: 'Enviaremos un código de verificación para confirmar esta modificación, seleccione el método de verificación por favor:',
      sendVerifyCode: 'código de verificación ya enviado a {contactType}',
      sms: 'móvil',
      mail: 'correo electrónico',
      mailVia: 'Envíe el código al buzón de correo',
      smsVia: 'Enviar código al teléfono móvil',
      errorLater: 'intente nuevamente en un momento ...',
      updateSuccess: 'modificación de dirección exitosa',
      // 多地址收款
      multAddress: {
        tag: 'Varias direcciones',
        addMultAddress: 'Añadir dirección',
        adjustProportion: 'Ajustar proporción',
        adjustMinPay: 'Ajustar umbral',
        address: 'Dirección',
        proportion: 'Proporción',
        remark: 'Nota',
        lastUpdateTime: 'Tiempo más nuevo',
        operation: 'Operación',
        emptyText: 'No has agregado ninguna dirección',
        emptyTextForMult: 'Sin datos, se pueden agregar hasta 20 direcciones',
        cancel: 'Cancelar',
        nextStep: 'Próximo paso',
        delete: 'Borrar',
        modify: 'Modificar',
        newAddressPlaceholder: 'Nueva dirección ',
        addressMaxCountTip:
          'Actualmente, puede agregar hasta {maxCount} direcciones de recepción en una subcuenta. Si necesita admitir más direcciones, comuníquese con el servicio de atención al cliente support@connectbtc.com.',
        updateBitconAddressTip:
          'Por la seguridad de los fondos, el pago se suspenderá dentro de las 48 horas posteriores a esta operación, y sus ganancias por cobrar se pagarán el primer día de liquidación después de 48 horas.',
        emptyRemark: 'No se agregaron notas',
        noAddressForAdjust:
          'No ha agregado ninguna dirección. Primero agregue algunas direcciones antes de ajustar la proporción o el umbral.',
        balanceMoveTip:
          'La criptomoneda no pagada de la subcuenta actual se transferirá a "Nueva dirección 1", confirme su operación.',
        confirmModalTitle: 'Verificación de seguridad',
        authentication: 'Autenticación',
        operationConfirm: 'Operación Confirmar',
        addAddressConfirmTip: 'Estás agregando {count} direcciones',
        modifyProportionConfirmTip:
          'Estás ajustando la proporción de cada dirección de recepción',
        deleteAddressConfirmTip: 'Estás eliminando la dirección <br/>{address}',
        selectBalanceMoveAddress: 'Elija una dirección de herencia',
        selectBalanceMoveAddressTip:
          'La criptomoneda no pagada de la dirección que se elimina es {balance}{coinType}. Luego de esta operación, será transferido a la nueva dirección seleccionada arriba en 24 horas.',
        cannotDeleteAddress: 'No se pudo borrar la dirección',
        deleteAddressRule:
          'Solo se pueden eliminar las direcciones con una proporción de pago igual al 0,00%.',
        // deleteAddressRuleDetail:
        //   '1. La proposición es igual al 0,00%;<br/>2. La dirección de minería inteligente de la misma subcuenta no está configurada como esta dirección.',
        // usedBySmart:
        //   'La minería inteligente de la subcuenta actual selecciona la dirección actual, por lo que esta modificación modificará simultáneamente la dirección de minería de la ametralladora.',
        operationSuccess: 'Operación exitosa',
        validate: {
          emptyAddress: 'Ingrese la nueva dirección',
          emptyAddressForUpdate: 'Ingrese la nueva dirección',
          emptyProportion: 'Complete la proporción de la dirección ',
          emptyProportioAfter: '',
          proportionInvalidate:
            'La proporción de cada dirección debe ser mayor o igual al 0% y la suma de todas las proporciones debe ser igual al 100%',
          maxCount:
            'Se pueden configurar hasta 20 direcciones para cada subcuenta',
          duplicateAddressPrefix:
            'La misma dirección solo se puede agregar una vez, pero la dirección ',
          duplicateAddressSuffix: ' se agrega repetidamente.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },

      //min pay
      currentMinPay: 'monto de pago mínimo actual:',
      minPayAllSubAccount:
        'aplicar el mismo monto de pago mínimo a todas las subcuentas de Bitcoin (BTC)',

      //Share to watcher
      watcher: 'observador',
      availableWatchers: 'disponible actualmente',
      watcherExpress:
        '* El observador puede acceder a datos de usted a través del enlace a continuación, pero no puede operar ni modificar su cuenta.',
      createWatcher: 'crear nuevo observador',
      modifyWatcher: 'editar acceso',
      authoritySettings: 'configuración de acceso',
      setWatcherName: 'añadir un nombre para observador',
      watcherName: 'nombre de observador',
      watcherNameTip: 'límite de caracteres 2~20',
      notEmptyWatcherNote: 'nombre de observador es obligatorio',
      tips: 'Consejos:',
      tip_dashboard:
        '1. Panel de control no incluye información sobre ingresos como "No pagados" y "Pagados";',
      tip_earning:
        '2. Ingresos incluye información como "No pagados" y "Pagados" de cada dirección.',
      moreSettings: 'más configuración',
      guardianRemark:
        'Puede compartir toda o parte de la información de la subcuenta (incluida la hashrate, la minero, etc.) con otros usuarios, quienes solo pueden ver sin poder modificar ninguna información.',
      guardian: 'cuenta de uso compartido',
      shareAccountsSetting: 'Compartir cuentas',
      accessKey: 'Access Key',
      generateAK: 'generar Access Key',
      puid: 'PUID',
      apiDoc: 'archivo API',
      confirmDeleteWatcher: 'confirmar borrar esta cuenta de observador?',
      watcherAuths_Dashboard: 'interfaz',
      watcherAuths_Dashboard_Tip: '(no incluye información de ganancia)',
      watcherAuths_Miners: 'página de minero',
      watcherAuths_Earnings: 'información de ganancia',
      watcherAuths_GetCoin: 'Regalo',

      // Alert setting
      alertItem: 'configuración de artículos de alerta',
      alertContacts: 'persona de contacto para alerta',
      hashrateAlert: 'alerta de hashrate',
      minerAlert: 'alerta número minero',
      alertFrequency: 'frecuencia de alerta',
      alertFrequencyTip:
        'Por motivos de política, la frecuencia de las alertas por SMS en algunos países y regiones se fija de una vez cada 24 horas. Esto se notifica por la presente.',
      warnMeWhenHashrate: 'línea de alerta de hashrate≤',
      warnMeWhenActiveMiner: 'alerta número minero activo',
      dontWarnMeTwice: 'intervalo mínimo entre dos alertas',
      hours: 'horas',
      apply: 'aplicar',
      operateFailed: 'operación fracasada!',
      contactsList: 'lista de contactos',
      cancel: 'cancelar',
      ok: 'confirmar',
      save: 'guardar',
      reset: 'reprogramar',
      newContact: 'contacto nuevo',
      editContact: 'editar contato',
      deleteContact: 'borrar contacto',
      confirmDeleteContact:
        'confirmar borrar esta persona de contacto para alerta?',
      name: 'nombre/comentario',
      phone: 'teléfono',
      email: 'correo electrónico',
      validateAlert: {
        nameEmpty: 'nombre/comentario es obligatorio',
        phoneRegionFormat: 'formato de código de país equivocado',
        phoneFormat: 'formato de número de teféfono equivocado',
        emailFormat: 'formato de correo electrónico equivocado',
        contactEmpty: 'debe dejar o su correo electrónico o su número de móvil',
      },
      max_email_contacts: 'admite al máximo 10 contactos de correo electrónico',
      max_phone_contacts: 'admite al máximo 5 contactos de teléfono',
      enabled_alert_need_hashrate:
        'no se detectó hashrate, y no se puede activar el alerta.',
      alertItemUpdateSuccess: 'configuración de artículos de alerta exitosa',

      done: 'entrega',

      okay: 'confirmar',
      emptyAddress: 'dirección es obligatoria',
      invalidAddress: 'dirección equivocada',
      emptyCode: 'código de verificación es obligatorio',
      wrongCode: 'código de verificación equivocado',
      emailVia: 'enviar correo a',
      textVia: 'enviar mensaje a',
      help: 'asistencia',

      contact: 'contaco:',
      close: 'cerrar',
      receivedress: 'dirección de recibo:',

      todayUpdateMinPay:
        'a partir de hoy el monto de pago mínimo de la ganancia de minería se ha modificado a :{minPay}',
      updateAddress: 'fecha y hora de modificación de dirección:',
      // pay with btc
      payWithBtcTip:
        '* Tras activar pago con BTC, su ganancia de minería se convertirá en Bitcoin a una hora fija cada día, lo que se registrará en el historial de ganancia y se pagará a la siguiente dirección independiente de pago con BTC. Por lo general, dicha hora fija es las 12:00 cada mañana. En circunstancias especiales, usted será notificado por separado.',
      convertedCurrency: 'moneda convertida: ',
      setBtcAddressTip: 'configure la dirección de recibo de BTC, por favor',
      btcAddress: 'dirección de BTC: ',
      conversionRate: 'tipo de cambio de referencia',
      convert: 'cambiar',
      getValue: 'usted recibirá ',
      conversionTip:
        '* tipo de cambio es para su referencia, y prevalecerá el resultado real del pago',
      lastUpdate: 'fecha de última actualización: ',
      on: 'activado',
      off: 'desactivado',
      turnOnPaywith: 'confirmar activar pago con BTC',
      turnOffPaywith: 'confirmar desactivar pago con BTC?',
      payOn: 'activado',
      payOff: 'desactivado',
      resend: 'enviar de nuevo',
      send: 'enviar',
      resendTip:
        'Si no recibe el código, espere un poco o intente unas cuantas veces más.',

      // cambiar por un solo botón One Switch
      oneSwitch: {
        attention: 'atención',
        optionCurrentMode: 'seleccione modo de cambio',
        switch: 'usted combiará la hashrate a {destMode}',
        switchAnotherMining: 'cambiando a modo {destMode}...',
        ethSwitchWarning:
          'Alerta: si su minero no admite cambiar DAG, puede provocar que la ganancia sea cero. Necesitará reiniciar la máquina manualmente para que vuelva a la normalidad. (Es conocido que la minero hormiga E3 no admite cambiar de ETC a ETH, lo que requiere reinicio manual) ',
        ethSwitchWarningLinkTxt: 'para más información, haga clic en',
        hashsHaveSwitched: 'hashrate ya cambiado a {destMode} para excavar;',
        checkSubAccount:
          'vea la subcuenta {subAccountName}- {subAccountRegionNode}',
        enter: 'ingresar',
      },
      // dirección de recibo de piscina inteligente
      smartAddress: {
        autoSwapSettlement: 'pago con cambio instantáneo',
        original: 'pago con moneda original',
        autoTip:
          'El moneda de ganancia de piscina inteligente se cambia a la moneda objetivo diariamente para el pago.',
        originalTip:
          'La ganancia de piscina inteligente se paga en BTC, BCH y BSV que son admitida por la piscina inteligente',
        originalCurrency: 'moneda de orígen',
        targetCurrency: 'moneda objetivo',
        earningsInSmartPool: 'ganancia de piscina inteligente',
        activated: 'activado',
        unactivated: 'desactivado',
        modifyTip:
          'La moneda objetivo del pago con cambio instantáneo de piscina inteligente está asociada y es coherente con la dirección de pago de la misma moneda en la misma subcuenta.',
        autoHistory:
          'quiere canjear sus ganancias históricas con cambio instantáneo？',
        notice1:
          'Por razones de seguridad, no se realizará ningún pago dentro de las 48 horas posteriores a la modificación de la dirección, y sus ganancias se pagarán como saldo el primer día de liquidación después de las 48 horas.',
        notice2:
          'Al activar la función de cambio instantáneo, las ganancias de minería de piscina inteligente se convertirán automáticamente en la moneda objetivo para el pago del mismo día. Al hacer clic en "confirmar" se considerará que usted acepta {fundLink}.',
        notice3:
          'La dirección en el modo inteligente está asociada y es coherente con la dirección de moneda única en la misma subcuenta (si se establecen varias direcciones, será coherente con la primera dirección)',
        originalNotice1:
          'La dirección en el modo inteligente está asociada y es coherente con la dirección de moneda única en la misma subcuenta (si se establecen varias direcciones, será coherente con la primera dirección)',
        currentPayoutMode: 'modo de pago actual: {mode}',
        fundLink: `《Acuerdo de Autorización de Cambio de Fondos CloverPool》`,
        originBtn: 'activar pago con moneda original',
        otcBtn: 'activar pago con cambio instantáneo',
        otcSuccess:
          'pago con cambio instantáneo para modo piscina inteligente ya activado existosamente, BTC será el moneda para pago de ganancia.',
        otcModalNotice2: `Después de activar cambio instantáneo para ganancias históricas, las ganancias históricas en la cuenta participarán en cambio instantáneo; si no está activado, solo las ganancias del ciclo de pago más reciente participan en cambio instantáneo.`,
        originSuccess:
          'La función de pago con moneda original en modo piscina inteligente se ha activado, y la moneda de minería de hashrate real se utilizará como moneda de pago de ganancia.',
      },
    },
    homepage: {
      btcSmartAgent: 'proxy inteligente BTC',
      agentExpress:
        'eficiente y transparente &emsp;&emsp;&emsp; minero individual visbile',
      bit64: 'Win7 y más nuevo, 64 dígitos',
      bit32: 'WinXP 32 dígitos',
      document: 'archivo de asistencia de windows',
      accessLinuxVersion: 'acceso directo de instalación de versión Linux',
      cuttingEdgeTechnology: 'tecnología avanzada',
      latestArchitecture:
        'arquitectura nueva de diseño original<br/> reune ventajas de todos',
      openSourceCode:
        'código de fuente abierta <br/> transparente y equitativo',
      highTestPressure: 'test bajo alta presión<br/>estabildad comprobada',
      lowOrphanedRate:
        'tasa de bloque huérfano ultra-baja <br/> rápido como un rayo',
      transparentAgent: 'software auxiliar nuevo<br/>brinda proxy transparente',
      standardsServices: 'nuevo estándar de la industría de minería',
      customerHotline: 'línea de servicio al cliente',
      highHashrateConsultation: 'atracción de clientes grandes',
      professionalCustomerService:
        'pool de minería con verdadero servcio al cliente profesional',
      bestVipServer: 'servidor VIP de estándard más alto de la industria',
      oneToOne:
        'analizadores de primera clase de la industria ofrecen análisis de caso personalizado',
      appAvailable: 'App de pool de minería permite administración fácil',
      earningsTimelyEveryday: 'pago puntual sin ningún retraso',
      transnationalTeamOperation:
        'primer pool de minería apoyado por un equipo transacional',
      currencies: 'moneda',
      dailyEarnings: 'ganancia diaria',
      hashrate: 'hashrate',
      hashrateChart: 'gráfica de hashrate',
      explorer: 'navegador',
      prices: 'precio',
      earningsModels: 'modo ganancia',
      minPayment: 'pago mínimo',
      help: 'asistencia',
      notePaytime:
        'Diariamente de 0:00 a 8:00 (UTC) pague las ganancias de ayer',
      yuanKwhElectric: 'Yuan / kw.hora (tarifa de electricidad)',
      todayEarnings: 'ganancia diaria aproximadamente:',
      piece: 'pieza',
      signIn: 'iniciar sesión',
      signUp: 'Inscribirse',
      power: 'comsumo de energía',
      hashrateProfitEst: 'ganacia por hashrate referencial',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst:
        'ganancia referencal de máquinas de minería de corriente principal',
      fee: 'tarifa',
      desc: 'pool de minería de servicio integral multi-moneda avanzado mundialmente',
      tutorial: 'tutorial',
      diff: 'dificultad actual',
      nextDifficultyEstimated: 'dificultad estimada para la próxima vez',
      dateToNextDifficulty: 'tiempo restante',
      Algorithm: 'algoritmo',
      backup: 'puerto alternativo',
      whyMe: {
        title: '¿Por qué elegir CloverPool?',
        leadingTechnology: 'tecnología avanzada',
        convenientManagement: 'administración fácil',
        revenueAssurance: 'garantía de ganancia',
        professionalServices: 'servicio profesional',
        desc1: `Código de fuente abierta, software gratuito para administración de máquina y pool de minería, versión oficial personalizada del firmware de overclocking para aumentar considerablemente su ganancia, proveedor de servicios de datos y tecnologías de blockchain.`,
        desc2:
          'App y operación en página web sencillas, número de móvil/correo electrónico para registrar fácilmente una cuenta, función de alerta independiente, dirección de ganancia, agrupación de máquinas de minería y enlace de observador con datos compartidos ',
        desc3: `Datos mineros reales y transparentes, modelo de ingresos FPPS avanzado, potente centro de liquidación, pago puntual, llueva o truene.`,
        desc4: `CloverPool cuenta con un equipo profesional de atención al cliente, el más alto estándar de servicios VIP personalizados y los mejores investigadores analizan los casos persona a persona en la industria.`,
      },
      app: {
        title: ' App',
        description:
          'pool de minería de servicio integral multi-moneda avanzado mundialmente',
        property1:
          'admite minería de varias monedas, administración de varias subcuentas y control fácil de enlaces compartidos de observador',
        property2:
          'visualización en tiempo real de datos completos de tipos de moneda le permite entender más rápidamente el estado dinámico de minería',
        property3: 'interfaz nueva brinda mejor experiencia de usuario',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'El modo de pago de ETH es FPPS/PPS + modo dual, y el modo actual es el modo FPPS; la plataforma ajustará el modo de pago de acuerdo con varios factores como los costos de transacción en cadena, el porcentaje de hashrate de este pool de minería, las condiciones del mercado y la ganancia del usuario.',
            'PPS+':
              'El modo de pago de ETH es FPPS/PPS + modo dual, y el modo actual es el modo FPPS; la plataforma ajustará el modo de pago de acuerdo con varios factores como los costos de transacción en cadena, el porcentaje de hashrate de este pool de minería, las condiciones del mercado y la ganancia del usuario.',
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'El equipo que solo mina ETC se conecta a esta dirección minera',
      zilMinerAddressTips:
        'El equipo de minería dual ETC y ZIL está conectado a esta dirección de minería',
    },
    advanceAccount: {
      title: 'administración de subcuenta - CloverPool',
      all: 'topo moneda',
      unit: 'unidad',
      subAccount: 'subcuenta',
      coin: 'moneda',
      hashrate: 'hashrate',
      allMiners: 'todas las máquinas de mineróa',
      guard: 'nombre de comentario',
      activeMiners: 'activo',
      inactiveMiners: 'inactivo',
      deadMiners: 'inválido',
      region: 'región',
      more: 'más',
      setting: 'configuración',
      totalHashrate: 'hashrate total',
      search: 'buscar',
      status: 'estado',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: 'A partir de hoy, si usted excava (Bitcoin Cash, Bitcoin) en el pool de minería de CloverPool, le regalamos Domain Coins (NMC), Elastos de forma gratuita.',
        bch: 'A partir de hoy, si usted excava (Bitcoin Cash, Bitcoin) en el pool de minería de CloverPool, le regalamos Domain Coins (NMC), Elastos de forma gratuita.',
        ltc: 'A partir de hoy, si usted excava (litecoin, LTC) en el pool de minería de CloverPool, le regalamos Dogecoin de forma gratuita que se paga en modo PPLNS',
        bcha: `<p>BCHA (BCHABC) es una moneda bifurcada de BCH. Para obtener más información, visite <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a>。</p>
          <p><b>fecha y hora de retiro:</b><br/>7 de diciembre de 2020 a las 08:00 a.m. al 7 de enero de 2021 a las 08:00 a.m. (hora de Singapur)
          </p>`,
      },
      beta: 'test beta en curso',
      whatsThis: 'qué es esto?',
      address: 'dirección:',
      newAddress: 'nueva dirección',
      addressComplete:
        'rellene dirección de recibo de moneda de regalo lo antes posible para evitar pérdida de ganancia ',
      bchaAddressComplete:
        'Después del 7 de enero de 2021, para usuarios que no rellenen la dirección, BCHA se borrará y no se volverá a emitir. Rellene dirección de recibo de moneda de regalo lo antes posible para evitar pérdida de ganancia.',
    },
    watcher: {
      expired: 'acceso de observador caducado',
      incorrect: 'enlace de observador incorrento',
    },
    activity: {
      pointsToday: 'puntos de hoy',
      pointsSum: 'puntos acumulados',
      ranking: 'ranking',
      earnings: 'ganancia',
    },
    minerRanking: {
      title: 'ranking de ganancia por minero',
      electric: 'gasto electricidad diario',
      dailyEarning: 'ganancia diaria',
      priceShutdown: 'precio de cierre',
      ALL: 'todo',
      CNY: 'yuan chino CNY',
      USD: 'dolar estadounidense USD',
      desc: 'ganancia, de más alta a más baja收益由高到低',
      asc: 'ganancia, de más baja a más alta',
      electricPercent: 'porcentaje de gasto electricidad',
      searchTip: 'ingrese el minero por favor',
      updated: 'actualizado',
      coin: 'moneda',
      emptyShow: 'no hay máquinas de minería que cumplan con las condiciones',
      rank: 'ranking',
      miner: 'minero',
      power: 'consumo',
      hashrate: 'hashrate',
      rev24h: 'output dirario',
      energy: 'gasto electricidad',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
