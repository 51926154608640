import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'English',
    header: {
      home: 'Pool Home',
      dashboard: 'Dashboard',
      getCoin: 'Get Coin',
      help: 'Help',
      miners: 'Miners',
      poolStats: 'Pool Stats',
      commissionEarnings: 'Commission Earnings',
      miningEarnings: 'Mining Earnings',
      service: 'Service',
      tools: 'Tools',
      setting: 'Settings',
      guardian: 'Sharing',
      guardianPermission:
        'You are an authorized user who has the read-only access to the account.',
      watcher: 'Watcher',
      watcherPermission:
        'Watcher is an authorized user who has the read-only access to the sub-account.',
      userCenter: 'User Center',
      SignOut: 'Sign Out',
      accountSetting: 'Account Settings',
      poolManagePanel: 'Pool Dashboard',
      watcherMode: 'Watcher Mode',
      scanToService: 'Scan QR code to get help',
      bitdeer: 'Bitdeer',
      hashnest: 'HashNest',
      explorer: 'Blockchain Explorer',
      app: 'App',
      getBCHA: 'BCHA Withdraw',
      candy: 'Candy',
      alertBar:
        '<b>On August 10th, 22:00-24:00 (UTC+8)</b>, the CloverPool mining pool will perform large-scale data maintenance. Some functions will be unavailable and the mining service will not be affected. <a href="https://help.cloverpool.com/hc/en-us/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">Click for details</a>',
      introTip:
        'Functions such as Dashboard, Miners, Earnings and Sub-account settings are unified in the "Pool Dashboard".',
    },
    nav: {
      'nav-explorer': 'Explorer',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Pool Service',
      'nav-pool-home': 'Pool Home',
      'nav-pool-data': 'Pool Data',
      'nav-pool-candy': 'Candy',
      'nav-pool-tools': 'Tools & Software',
      'nav-pool-cs': 'Customer Service',
      'nav-wallet': 'Wallet',
      'nav-help': 'Help',
      'nav-navigation': 'Blockchain Navigation',
      'nav-inscriptions': 'Inscribe',
      'nav-app': 'APP',
      'nav-collaborate': 'Cooperation',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Tools',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'Current Balance',
      coinAddress: '{coinType} Address',
      setAddress: 'Please set a {coinType} Address.',
      multAddressEnabled: 'Multi-address payment is enabled',
      menu: {
        hide: 'Hide',
        address: 'Wallet Address',
        minPay: 'Minimum Payment',
        payWithBtc: 'Pay with BTC',
        earingsHistory: 'Earnings',
        shareData: 'Share Accounts',
        watcher: 'Watcher Settings',
        apikey: 'API KEY',
        alertSettings: 'Alert Settings',
        onebuttonSwitch: 'One-button Switch',
        userCenter: 'User Center',
        signOut: 'Sign Out',
        delete: 'Delete',
      },
      confirm: 'OK',
      cacel: 'Cancel',
      deleteConfirm: 'Delete Confirmation',
      cannotDelete: 'Unable to delete the sub-account',
      cannotDeleteView: 'Cannot delete the sub-account currently being viewed',
      deleteCondition:
        'Unable to delete sub-account<span class="subAccountName">{{accountMessage}}</span>,Only sub-accounts that meet the following two conditions can be deleted:',
      cannotDeleteReason:
        '1、The hashrate of the sub-account in the past 180 days is 0;<br/>2、The unpaid cryptocurrency of the sub-account(including Gift coins) is 0;<br/>3、The sub-account does not belong to any kinds of special sub-accounts.',

      deleteName: 'You are deleting the sub-account :',
      deleteInfo:
        '* After clicking "OK", the sub-account will be permanently deleted and cannot be retrieved;<br/>* After deletion, the hashrate curve, income record and other data of the sub-account cannot be retrieved.',
      deleteSuccess: 'Sub-account deleted successfully',
    },
    footer: {
      services: 'Services',
      Products: 'Products',
      Help: 'Help',
      btcTool: 'BTC Tool',
      btcSmartAgent: 'BTC Smart Agent',
      btcComFirmware: 'CloverPool Firmware',
      blockchainExplorer: 'Blockchain Explorer',
      App: 'App',
      apiDocs: 'API Docs',
      vipApplication: 'VIP Application',
      cloudMinePoolService: ' Cloud Mine Pool Construction Service',
      miningTutorial: 'Mining Tutorial',
      FAQ: 'FAQ',
      Announcements: 'Announcements',
      ticketSystem: 'Ticket System',
    },
    pageTitle: {
      home: "CloverPool - The world's leading comprehensive multi-currency mining pool",
      dashboard: 'Dashboard - CloverPool',
      miners: 'Miners - CloverPool',
      earningHistory: 'Earnings History - CloverPool',
      earningHistoryForInvite: 'Commission Earnings - CloverPool',
      poolStats: 'Pool Data - CloverPool',
      guardian: 'The Guardian Invitation Link',
      guardianBind: 'Binding The Guardian Invitation Link - CloverPool',
      subAccount: 'Create a sub-account - CloverPool',
      subAccountManage: 'Sub-account Manage - CloverPool',
      settings: 'Settings - CloverPool',
      getCoin: 'Get Coin - CloverPool',
      tools: 'Tools & Software - CloverPool',
      bitdeer: 'Bitdeer - CloverPool',
      watcherExpired: 'Watcher authority expired - CloverPool',
      watcherIncorrect: 'Watcher link error - CloverPool',
      fundAuthorization: 'Fund Authorization Agreement - CloverPool',
      app: 'App Download - CloverPool',
      invite: 'Invitation Reward - CloverPool',
    },
    breadCrumbs: {
      home: 'Home',
      dashboard: 'Dashboard',
      miners: 'Miners',
      earningHistory: 'Earnings',
      poolStats: 'Pool Data',
      shareAccount: 'Share Accounts Setting',
      guardianBindingInvitation: 'Binding The Guardian Invitation Link',
      guardianBindingLink: 'The Guardian Account Management',
      subAccountManage: 'Sub-account Manage ',
      hiddenSubAccounts: 'Hidden Sub-accounts Manage',
      settings: 'Settings',
      getCoin: 'Get Coin',
      tools: 'Tools & Software',
      fundAuthorization: 'Fund Authorization Agreement',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: 'Smart Pool',
      otcNotice: `You are about to switch your hashrate to Smart Pool, Click "OK" will be deemed to read and agree with {fundLink}`,
      fundLink: `"CloverPool Smart Pool Service Agreement"`,
      autoTip: `Using the smart pool mode will mean that you host the hashrate to CloverPool, which will switch among BTC, BCH and BSV with the most profit for you to mine.`,
      originalTip:
        'If you choose original currency mode, SHA256 hash will solely mine the coin selected by you instead of automatic switch.',
    },
    operation: {
      name: 'Operation',
      success: 'Operation Successful!',
      failed: 'Operation failed!',
    },
    earning: {
      balanceTip:
        'After the settlement is completed, we will generally complete the payment within 24 hours, except for the following situations:<br>1.No address is set;<br>2. The address is set but the cumulative settlement amount has not reached the minimum payment threshold;<br>3. Operations such as adding an address, modifying an address, deleting an address, or modifying a ratio have triggered the 48-hour payment suspension rule;<br>4. Risk Control Rule is triggered;<br>5. The currency undergoes a hard fork,  or encounters a 51% attack, or other major upgrades or accidents.',
    },
    meta: {
      keywords:
        'Bitcoin,BTC,BCH,Bitcoin Cash,LTC, Litecoin,ETC,KAS,Kaspa,via,viabtc,f2pool,antpool,foundry,binance,luxor,poolin,pool,miners,worker,machine,tools,mint,brc20,runes,cloverpool,bitdeer,bitmain',
      description:
        'CloverPool is a whole new choice for miners, that supports mining of Bitcoin, Litecoin, Bitcoin Cash, Kaspa, and other cryptocurrencies. With much better user experience, much lower fees and much stronger service.',
    },
    gotIt: 'Got it',
    showAll: 'Show All',
    foldMe: 'Fold All',
    copied: 'Copied',
    more: 'More',
    none: 'None',
    on: 'On',
    off: 'Off',
    all: 'All',
    modify: 'Modify',
    submit: 'Submit',
    ok: 'OK',
    cancel: 'Cancel',
    new: 'Add',
    address: 'Address',
    notFoundTip: 'Sorry, the page you are looking for does not exist',
    export: 'Export',
    startDate: 'Start Date',
    endDate: 'End Date',
    smartPool: 'Smart Pool',
    status: 'Status',
    notice: 'Notice',
    yes: 'Yes',
    no: 'No',
    coin: 'Coin',
    custom: 'Custom',
    hide: 'Hide',
    less: 'Less',
    details: 'Details',
    region: {
      cn: 'Beijing(China)',
      sz: 'Shenzhen(China)',
      us: 'US',
      eu: 'EU',
      sg: 'Singapore',
    },
    LegalStatement:
      'I hereby affirm: I am not a resident of Cuba, Iran, North Korea, Syria, Russia, or other countries or regions subject to sanctions administered or enforced by any relevant countries or governments or international agencies, and I am not a resident of Mainland China. The mining pool service provided by CloverPool is in compliance with laws, regulations and related policies in the country or region where I am located.',
    LegalStatementConfirm:
      'All legal risks and liabilities caused by or arising from the fact that it is illegal to use the services provided by CloverPool in the country or region where I live shall be solely borne by me.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
