import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} Доход/M',
      accountEarning: 'Доход аккаунта',
      active: 'Активный',
      activeMiner: 'Активный майнер',
      amoutPaid: 'Оплачено',
      announcement: 'Объявление',
      cannotConnect: 'Невозможно подключиться?',
      urlConfigGpu: ' Поддерживает майнеры на видеокарте',
      urlConfigNicehash: ' Оптимизирован для Nicehash',
      urlConfigGpuDcr: ' Поддерживает майнеры-муравьиDR3，Claymore，gominer',
      urlConfigNicehashDcr:
        ' Поддерживает Nicehash, Innosilicon и другие майнеры',
      customerTitle: 'Обслуживание клиентов',
      dailyHashrate: 'Ежедневный хешрейт',
      dashboard: 'Пользовательская панель',
      earnMore: 'CloverPoolРазница в доходе в майнинг-пуле и вне',
      earning: '{coinType} Доход',
      eventConnected: 'Подключено',
      eventDisconnected: 'Отключить',
      eventMiner: 'Майнер',
      eventName: 'Сообщения',
      eventSkip: 'Пропустить',
      eventTitle: 'Уведомление о событии',
      eventlatestLogs: 'Ниже история сообщений',
      hastrateChart: 'График хешрейта',
      history: 'Записи платежей',
      hour: '{n} ч.',
      hours: 'ч.',
      inActive: 'Неактивный',
      lastPaymentTime: 'Время предыдущего платежа',
      markAllRead: 'Пометить все как прочитанные',
      minerContent:
        'Войдите на страницу настройки программы для майнинга, укажите свой субаккаунт (рекомендуется не указывать пароль) и адрес майнинга. Сохраните и откройте в Excel. В течение минуты майнер автоматически добавится на веб-страницу майнинг-пула.',
      minerNameStand:
        'Правило наименования майнера: субаккаунт+точка+серийный номер, например Ваш субаккаунт{sub_account}, название майнера можно настроить{sub_account}.001、{sub_account}.002，и так далее, одно название соответствует одному майнеру。',
      minerName:
        'пример названия майнера："{sub_account}.001", "{sub_account}.002".',
      miner: 'Майнер',
      miners: 'шахтеры',
      miner1: 'Шахтер I-типа',
      miner2: 'Шахтер II типа',
      miningAddress: 'Адрес майнинга',
      miningEarnings: '{coinType} Отдача вычислительной мощности',
      minute: 'мин.',
      more: 'Еще',
      networkDiff: 'Текущая сложность',
      networkHashrate: 'Хешрейт сети',
      networkStatus: 'Состояние сети',
      nextDifficult: 'Следующая сложность',
      noMiner:
        'Добавьте майнер в майнинг-пул, чтобы начать работу. Рекомендуем использовать майнинг-пул CloverPool',
      btcTools: 'Агентские программы。',
      noEvent: 'Пока нет уведомлений о событиях!',
      nowHashrate: 'Текущая вычислительная сила',
      pendingPayouts: 'Ожидание оплаты',
      poolHashrate: 'Хешрейт майнинг-пула',
      realHashrate: '{coinType} Xешрейт',
      realtime: 'В реальном времени',
      timeRemain: 'До регулировки осталось',
      title: 'Пользовательская панель - CloverPool',
      today: 'Прогноз на сегодня',
      unpaid: 'Неоплаченный',
      yesterday: 'Вчерашний доход',
      yesterdayEarnTip: 'Относится к доходам с 0: 00-24: 00 дня до UTC.',
      todayEarnTip: 'Прогноз дохода BTC майнинга SHA256',
      charts: {
        hashrate: 'Хешрейт',
        minerCharts: 'Кривая вычислительной мощности майнера',
        myCharts: 'Кривая моей вычислительной мощности',
        poolCharts: 'Кривая вычислительной мощности майнинг-пула',
        reject: 'Процент реджектов',
        time: 'Время',
      },
      yesterday_earn: 'Вчерашний доход',
      yesterday_hashhrate: 'Действительная Хешрейт вчера (без реджектов)',
      earnings_per_100T: 'Доход на 100Т',
      //Заявление об ответственности
      statement:
        '* Вы не можете использовать (или пытаться использовать) данный сервис каким-либо иным способом, кроме указанного либо рекомендованного нами. Вы обязуетесь не использовать (или пытаться использовать) данный сервис какими-либо незаконными либо неправомерными способами, или с целью поддержки какой-либо незаконной либо неправомерной деятельности. Заходя на данный веб-сайт и регистрируясь для получения данного сервиса, Вы обязуетесь и гарантируете, что являетесь лицом, которое может законно использовать данный сервис в соответствии с законодательством в Вашей юрисдикции.',
      // Прогноз дохода
      estimatedEarnings: {
        title: 'Расчетный доход',
        hours24Earnings: 'Прогноз на 24 ч.',
        tip24:
          'Рассчитывается на основе средней сложности майнинга валюты за последние 24 часа',
        realTimeEarnings: 'Прогноз в реальном времени',
        realTimeEarningsTip:
          'Рассчитывается на основе последней сложности майнинга валюты',
      },
    },
    subAccount: {
      title: 'Создание субаккаунта - CloverPool',
      createWorker: 'Создать субаккаунт',
      noNode: 'Нод не выбран!',
      noCoinType: 'Валюта не выбрана!',
      selectRegion: 'Выбрать регион',
      setWorker: 'Настроить имя субаккаунта',
      enterWorker: 'Введите имя субаккаунта',
      enterAddress: 'Введите адрес получения',
      coinTypeEx:
        'CloverPool поддерживает {supportCoins} Скоро еще больше валют! Ожидайте!',
      regionEx:
        'CloverPool Наши серверы размещены по всему миру. Выберите ближайший для наиболее эффективной работы.',
      workerEx:
        'Настроенный субаккант будет привязан к выбранному региону. Количество настроенных субаккаунтов не может превышать 2000. Название данной субаккаунта с серийным номером майнера является именем майнера на странице настройки майнера（worker name），如: "{name}.001"，"{name}.002"。',
      workerCaution:
        '* Обратите внимание, что субаккаунт не поддерживает майнинг в разных регионах. Субаккаунт, созданный в текущем регионе, не существует в других регионах. Если у Вас есть майнеры в других регионах, создайте новый аккаунт, соответствующий нужному региону.',
      setAddress: 'Заполнить адрес получения валюты',
      save: 'Создать и майнить',
      verifyWorker: 'Имя субаккаунта может содержать от 3 до 20 букв и цифр',
      option: 'Опционально',
      'optional-BTC':
        'Примечание. Старый формат адреса BCH аналогичен адресу BTC. Будьте внимательны при заполнении во избежание валютных потерь.',
      'optional-BCH':
        'Примечание. Старый формат адреса BCH аналогичен адресу BTC. Будьте внимательны при заполнении во избежание валютных потерь. Рекомендуется использовать новый формат адреса',
      'optional-UBTC':
        'Примечание. Старый формат адреса BCH аналогичен адресу UBTC. Будьте внимательны при заполнении во избежание валютных потерь.',
      'optional-SBTC':
        'Примечание. Старый формат адреса BCH аналогичен адресу SBTC. Будьте внимательны при заполнении во избежание валютных потерь.',
      'optional-coin':
        'Внимание. Будьте внимательны при заполнении адреса { coin_type } во избежание валютных потерь.',
      selectCoinType: 'Выбрать валюту',
      automatic: 'Автогенерация',
      recommandWallet: 'Рекомендуем использовать кошелек CloverPool',
      withdrawToExchange: 'Вывести на биржу',
      withdrawToWallet: 'Вывести в кошелек',
    },
    tools: {
      // title: 'Инструменты - CloverPool',
      // tools: 'Инструменты',
      // fppsPps: 'Инструмент сопоставления динамики FPPS и PPS',
      // aboutFpps: 'В чем преимущество FPPS над PPS?',
      // charts: 'Проверьте данные динамики',
      // watchCharts: 'Нажмите, чтобы сравнить',
      // miniCalc: 'Калькулятор майнинга',
      // miniCalcExpress: 'Рассчитывайте прибыль майнинга на основе вычислительной мощности, энергопотребления и затрат на электроэнергию с учетом сложности и курса валют',
      // miniMiniCalc: 'Мини-калькулятор майнинга',
      // miniMiniCalcExpress: 'Быстро рассчитывайте прибыль майнинга на основе вычислительной мощности, сложности и курса валют',
      // strat: 'Начать',
      // btcTool: 'CloverPool Набор инструментов майнинг-пула',
      // btcToolIntroduce: 'Выполняет серверные настройки майнера, быстрый и удобный',
      // download: 'Нажмите, чтобы загрузить'
      btcSmartAgent: 'BTC Интеллектуальный агентский сервис',
      agentExpress:
        'Эффективный и прозрачный Доступные для просмотра майнеры: <br/>',
      agentExpressBanner:
        'Эффективный и прозрачный <i style="margin-right:20px"></i> Доступные для просмотра майнеры:',
      bit: 'bit',
      document: 'Справочные документы для Windows',
      poolApp:
        'CloverPool приложение майнинг-пула многофункциональное приложение',
      poolRealTime: 'Просмотр данных майнинг-пула и майнера в реальном времени',
      poolOpeation: 'Удаление, изменение и другие действия с майнером',
      poolComming: 'Доступна функция сигнализации',
      poolDownlaod: 'Нажмите, чтобы загрузить',
      title: 'Инструменты - CloverPool',
      tools: 'Инструменты',
      fppsPps: 'Инструмент сопоставления динамики FPPS и PPS',
      aboutFpps: 'В чем преимущество FPPS над PPS?',
      charts: 'Проверьте данные динамики',
      watchCharts: 'Нажмите, чтобы сравнить',
      miniCalc: 'Калькулятор майнинга',
      miniCalcExpress:
        'Рассчитывайте прибыль майнинга на основе вычислительной мощности, энергопотребления и затрат на электроэнергию с учетом сложности и курса валют',
      miniMiniCalc: 'Мини-калькулятор майнинга',
      miniMiniCalcExpress:
        'Быстро рассчитывайте прибыль майнинга на основе вычислительной мощности, сложности и курса валют',
      start: 'Начать',
      btcTool: 'CloverPool Инструмент BTC',
      btcToolIntroduce:
        'Выполняет серверные настройки майнера, быстрый и удобный',
      download: 'Нажмите, чтобы загрузить',
      ipToolsTitle: 'CloverPool Набор IP инструментов майнера',
      ipToolsDes:
        'Поддержка всей видов муравей-майнеров, изменения IP и прямое подключение к майнинг-пулу',
    },
    miners: {
      createGroup: 'Создать новую группу',
      deleteGroupTitle: 'Удалить группу',
      editGroupTitle: 'Изменить группу',
      all: 'Все',
      default: 'Не группировать',
      addGroup: 'Создать новую группу',
      addGroupInfo:
        'Примечание. Выбранный майнер будет автоматически добавлен в новую группу',
      groupNameLabel: 'Имя группы',
      addGroupEmpty: 'Имя группы не может быть пустым',
      groupRejected: 'Процент отклонения', // Real time rejection rate
      groupNameLimit: 'Имя группы должно содержать от 3 до 20 символов',
      inactive: 'Неактивный',
      active: 'Активный',
      dead: 'истекший',
      moveTo: 'Переместить в',
      remove: 'Удалить',

      allTooltip:
        'Включить: активный майнеры ({active}) + неактивные майнеры（{inactive}）',
      inactiveTooltip:
        'шахтеры, не отправлявших результат расчета (Share) более 10 минут。',
      activeTooltip:
        'шахтеры, нормально отправляющих результат расчета (Share)',
      deadTooltip:
        'шахтеры, не отправлявших результат расчета (Share) более 24 часов',

      home: 'Главная',
      miners: 'Майнер',

      workerName: 'Имя Worker',
      Hashrate: 'В реальном времени',
      Rejected: 'Процент отклонения',
      dailyHashrate: 'Ежедневный хешрейт',
      firstShare: 'Первое подключение',
      LastShare: 'Последнее подключение',
      groupName: 'Имя группы',

      delMillTitle: 'Удалить информацию этих майнеров?',
      delMillContext:
        'Эта операция удалит все выбранные вами майнеры, пожалуйста, действуйте осторожно.',
      delMillFailed: 'Сбой удаления',

      delGroupTitle: 'Удалить группу {name}?',
      delGroupContext: 'Все сгруппированные майнеры будут перемещены из группы',

      moveTitle: 'Переместить майнер',
      notChecked: 'Выберите майнеры, доступен множественный выбор',
      moveContext:
        'Это действие переместит все выбранные майнеры в группу {groupName}',
      moveFaild: 'Невозможно переместить в текущую группу',

      searchPlaceholder: 'Введите название майнера',
      reportTime: 'Время создания отчета',
      refresh: 'Обновить',
      editGroupName: 'Изменить имя группы',
      workListEmpty: 'В данной группе нет майнеров',
      exist: 'Такая группа уже существует',
      goOldVersion: 'К старой версии',
      dataExport: `“{name}” Экспорт данных вычислительной мощности группы`,
      exportTip:
        'Выберите промежуток времени для экспортируемых данных. Макс. промежуток: 30 дней. Формат файла для экспорта: CSV。',
      exportTip2:
        'Хешрейт за текущий день рассчитывается с 00:00 до 23:59. Хешрейт сегодня рассчитывается с 00:00 до времени экспорта.',
    },
    minerInfo: {
      miners: 'Майнер',
      hashrate: 'Хешрейт 1 майнера',
      runningCondition: 'Рабочий статус',
      min: 'мин.',
      hour: '{n} ч.',
      hashrateAxis: 'Хешрейт',
      rejected: 'Процент реджектов',
      status: 'Статус',
      lastShare: 'Последнее подключение',
      lastShareIP: 'Последняя отправка IP',
      hashrateChart: 'График хешрейта',
      realTime: 'В реальном времени',
      trans: {
        seconds: 'сек.',
        mins: 'мин.',
        hours: 'ч.',
        ago: 'до',
        ACTIVE: 'Активный',
        INACTIVE: 'Неактивный',
        DEAD: 'мертвых',
      },
    },
    earningHistory: {
      title: 'История дохода - CloverPool',
      earn: 'Прибыль от майнинга',
      Unpaid: 'Неоплаченный',
      EarningsToday: 'Расчетная добыча',
      EarningsTodayTip:
        'Это относится к приблизительной сумме дохода PPS, которая была добыта с 0:00 (UTC) до этого момента, и эта сумма может отличаться от ваших фактических доходов.',
      EarningsYesterday: 'Вчерашний доход',
      Time: 'Дата добычи',
      NetworkDifficulty: 'Сложность',
      Earnings: 'Доход',
      Payment: 'Время платежа',
      payWithOTCAmount: '以 {otcType} Рассчитать доход: {amount}',
      Mode: 'Режим',
      Address: 'Адрес',
      PaidAmount: 'Заработок',
      yesterday: 'Относится к доходам с 0: 00-24: 00 дня до UTC.',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          'Зачисление на баланс, так как адрес получения не указан。',
        PENDING_CHANGED_ADDRESS:
          'Платеж будет выполнен через 48 часов после изменения адреса. Внимание. Если Вы не изменяли адрес получения, возможно Ваш аккаунт (эл. почта/телефон) был взломан. Немедленно зарегистрируйте новый аккаунт с помощью другой эл. почты или номера телефона и переключите майнеры на новый аккаунт。',
        PENDING_NOT_ENOUGH:
          'Зачисление на баланс, так как сумма меньше указанного мин. значения платежа (по умолчанию :limit).',
        PENDING_POOL_SETTLE_FAULT:
          'Ошибка майнинг-пула. Сбой отправки суммы. Больше такого не повторится.',
      },
      depositeAmount: 'Сохранить в баланс',
      diffChg: 'Изменение сложности: ',
      equalsPps: 'учитывая PPS',
      Chg: 'Дневное изменение',
      status: 'Статус платежа',
      paid: 'Оплачено',
      waitingPay: 'Ожидание платежа',
      reason: 'Причина',
      fppsMoreEarn: '{mode} Дополнительный доход:',
      fppsContain:
        'По сравнению с PPS заработок модели {mode}  увеличился примерно на  {pps96}',
      noEarningsInformation: 'Пока нет данных дохода',
      earningDetail: 'Детали заработка',
      Equivalent: 'эквивалентная сумма',
      extraProfits: 'Дополнительный доход',
      autoSwap: 'Быстрый расчет',
      original: 'Расчет исходная валюты',
      Swapped: 'Выполнен обмен',
      swapping: 'Выполняется обмен',
      otcEarningTip:
        'В режиме быстрого расчета доход исходной валюты рассчитывается на основе фактического обменного курса быстрого расчета на дату выставления счета Matrixport',
      originEarningTip:
        'В режиме расчета исходной валюты доход исходной валюты рассчитывается на основе обменного курса быстрого расчета на 8:00 утра в день выставления счета Matrixport',
      exportEarningsData: 'Экспорт данных дохода',
      exportTip:
        'Он может поддерживать данные за 180 дней, а формат экспортируемого файла - XLS.',
      originStatusTip:
        'Выполнен обмен дохода в целевую валюту через функцию быстрого обмена. Для получения подробной информации см. страницу дохода в режиме оружия',
      swappingTip:
        'Выполняется обмен текущего дохода или невозможно выполнить обмен дохода (сумма ниже мин. значения)',
      swappedTip:
        'Выполнен обмен дохода в целевую валюту через функцию быстрого обмена. Для получения подробной информации см. страницу дохода в режиме оружия',
      // upgrade TODO 收益改版新增
      settlementHashrate: 'Расчетный хешрейт',
      addressRatio: 'Соотношение адресов',
      addressEarnings: 'Адрес Заработок',
      paymentTime: 'Время оплаты',
      TimeTip:
        'Дата добычи указана по времени UTC. Например, 1 января 2020 г. соответствует 00:00:00 1 января 2020 г. 00:00:00 2 января 2020 г. по всемирному координированному времени.',
      EarningsTip:
        'Сумма дохода рассчитывается "Расчетным центром CloverPool" на основе нескольких факторов: метода распределения доходов, сложности сети, хешрейта расчетов, базового вознаграждения и вознаграждения за Tx за блок, а также ставок комиссии и т. Д. теоретические заработки, рассчитанные вами в калькуляторе, могут отличаться.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Комиссионные доходы',
      inviteWorkerAccount: 'Субаккаунт приглашенного',
      inviteRebateRatio: 'Ставка комиссии',
      inviteRebateAmount: 'Сумма комиссии',
      rebateExportTip:
        'Он может поддерживать данные за 60 дней, а формат экспортируемого файла - XLS.',
    },
    poolStats: {
      title: 'Статистика майнинг-пула - CloverPool',
      poolStats: 'Статистика',
      hashrate: 'Хешрейт',
      coinType: 'Тип валюты',
      miners: 'Майнер',
      found: 'Результативность майнинга',
      hashrateChart: 'График хешрейта',
      blocksRelayed: 'Циркуляция блока',
      height: 'Высота',
      time: 'Время',
      blocks: 'Блок',
      blockHash: 'Хэш блока',
      ddaScore: 'DAA Score',
      reward: 'Награда блока',
      day: 'д.',
      region: 'Регион',
      lucky: 'Удача',
      btc: 'Bitcoin',
      ltc: 'Litecoin',
      nmc: 'Namecoin',
      zec: 'Zcash',
      bcc: 'BCH',
      bch: 'BCH',
      eth: 'Ethereum',
      etc: 'Ethereum Classic',
      dcr: 'DCR',
      doge: 'Doge',
      grin: 'GRIN',
      sbtc: 'Super Bitcoin',
      ubtc: 'UBTC',
    },

    // Наблюдатель
    guardian: {
      expiredDeadline: '(:day Автоудаление)',
      guardianRemark:
        'Вы можете делиться информацией о субаккаунте (включая вычислительную мощность, майнеры и др.) частично или полностью с другими пользователями. Информация доступна только для просмотра, изменение невозможно',
      createGuardian: 'Добавить пользователя',
      remark: 'Примечание：',
      user: 'Пользователь:',
      manage: 'Управление',
      status: 'Статус:',
      theGuardian: 'Поделиться аккаунтом:',
      creationDate: 'Дата создания:',
      datePrevious: 'Дата последнего использования:',
      bounded: 'Привязан',
      delete: 'Удалить',
      deleteGuardianLink: 'Удалить ссылку для доступа к аккаунту',
      createGuardianInvitation:
        'Создать ссылку для приглашения к доступу в аккаунт',
      guardianAccountManagement: 'Управление аккаунтом',
      modifyRemark: 'Изменить название примечания',
      validateRemarkEmpty: 'Название примечания не может быть пустым',
      enterRemark: 'Введите имя примечания',
      noteInformation:
        'Напоминаем. Данное имя используется для разных аккаунтов. Рекомендуется использовать контактные данные оппонента。',
      confirm: 'OK',
      create: 'Создать',
      cancel: 'Отмена',
      guardianSuccessfullyCreated:
        'Ссылка для приглашения к доступу в аккаунт создана',
      copyLink: 'Копировать ссылку',
      authorized:
        'После удаления право оппонента управлять Вашим аккаунтом будет отменено',
      noteSendGuardian:
        'Напоминаем. Отправляйте ссылку надежным лицам, которым требуется управление майнерами. Данную ссылку можно открыть только один раз. Если срок действия оповещения оппонента истек, создайте новую ссылку。',
      close: 'Закрыть',
      subaccount: 'Субаккаунт',
      coinType: 'Тип валюты',
      region: 'Регион',
      guardianStatus: 'Статус',
      guardianSelectExpress:
        'Управляя доступом к статусу совместного пользования Вы можете самостоятельно выбирать субаккаунты, которыми могут управлять другие лица (по умолчанию просмотр доступен для всех субаккаунтов)',
      invitTitle: 'Поделиться',
      acceptTitle: 'Принять приглашение',
      losePower:
        'После удаления Вы потеряете право управлять аккаунтом оппонента。',
      show: 'Отображать',
    },
    // Ссылка приглашения хранителя для привязки
    guardianBind: {
      bindingGuardianInvitation:
        'Привязать ссылку для приглашения к доступу в аккаунт',
      advance: 'VIP',
      guardianAccountManagement: 'Управление аккаунтом:',
      confirmGuardianBind: 'Защита и привязка аккаунта',
      remark: 'Имя примечания',
      enterRemark: 'Введите имя примечания',
      suggestName:
        'Рекомендуется использовать имя оппонента для удобства управления. Если значение не указано, по умолчанию используется номер телефона или адрес эл. почты оппонента.',
      guardian: 'Привязать',
      cancel: 'Отмена',
      confirm: 'Подтвердить',
      manageView: 'Управлять и обслуживать все субаккаунты?',
      suggestUsingName:
        'Рекомендуется использовать имя оппонента для удобства управления. Если значение не указано, по умолчанию используется номер телефона или адрес эл. почты оппонента。',
      sms: 'Телефон',
      mail: 'Эл. почта',
      code: '{mode}Код подтверждения',
      send: 'Получить',
    },
    account: {
      subAccountManage: 'Управление субаккаунтом',
      currentAccount: 'Текущий аккаунт',
      locationRegion:
        'Вы расположены <strong>{coin}</strong> <strong>{region}</strong> Сервер',
      locationEx:
        'Каждому субаккаунту соответствует фиксированная валюта и регион. После настройки валюта и регион нельзя изменить. При необходимости можно создать новый субаккаунт。',
      general: 'Общая версия',
      advance: 'Расширенная версия',
      create: 'Создать  аккаунт',
      algorithm: ' Алгоритм',
      oneButtonSwitch: 'Выключатель',
      addOthersCoins: 'Добавить другие валюты алгоритма',
      supportCoins: 'Поддерживает',
      hiddenSubAccounts: 'Скрыть управление субаккаунтом',
      hashAlert: 'Отлично',
      viewHideSubAccount: 'Просмотр скрытых субаккаунтов',
      addSubAccount: 'Добавить субаккаунт',
      recover: 'Вернуть',
      latestAlert: {
        hashrate: 'Реальная Хешрейт {symbol} {expect} {unit}',
        miners: 'Реальное количество майнеров: {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'Субаккаунт',
      currency: 'Тип валюты',
      region: 'Регион',
      walletAddressAndMinimumPayment: 'Адрес получения и мин. сумма оплаты',
      walletAddress: 'Адрес получения',
      shareData: 'Поделиться <br/>аккаунтом',
      shareDataTip:
        'Вы можете поделиться всей или частью информации о вашей дополнительной учетной записи с другими людьми, которые могут только просматривать ее, но не могут ее изменять; вы также можете получать всю или часть информации о субсчетах других людей в режиме только для чтения.',
      alertSettings: 'Настройки уведомлений',
      mywatcher: 'Настройки Наблюдателя',
      apikey: 'API KEY',
      payWithBtc: 'Рассчитывается BTC',
      //wallet address & minimum payment
      setAddress: 'Укажите адрес получения {coinType}',
      address: 'Адрес получения',
      createAddress: 'Создать адрес',
      modifyAddress: 'Изменить адрес',
      modify: 'Изменить',
      minPay: 'Мин. сумма оплаты',
      lastUpdateTime: 'Время изменения адреса',
      updateBitconAddressTip:
        'Примечание. Для сохранности средств платеж будет приостановлен в течение 48 часов после этой операции, а ваш заработок будет использован в качестве баланса и выплачен в первый расчетный день после 48 часов.',

      old: 'Старый адрес',
      new: 'Новый адрес',
      continue: 'Далее',
      addAddress: 'Ввести',
      enterCode: 'Введите код подтверждения',
      newBit: 'Ваш новый адрес',
      way: 'Для подтверждения изменений будет отправлен код. Выберите способ отправки:',
      sendVerifyCode: 'Код подтверждения отправлен{contactType}',
      sms: 'Телефон',
      mail: 'Эл. почта',
      mailVia: 'Отправьте код на почтовый ящик',
      smsVia: 'Отправьте код на мобильный телефон',
      errorLater: 'Повторите попытку позже...',
      updateSuccess: 'Адрес изменен',
      // 多地址收款
      multAddress: {
        tag: 'Несколько адресов',
        addMultAddress: 'Добавить адрес',
        adjustProportion: 'Отрегулируйте процент',
        adjustMinPay: 'Отрегулируйте порог',
        address: 'Адрес',
        proportion: 'Пропорции',
        remark: 'Примечание',
        lastUpdateTime: 'Самое новое время',
        operation: 'Операция',
        emptyText: 'Вы не добавили ни одного адреса',
        emptyTextForMult: 'Нет данных, можно добавить до 20 адресов',
        cancel: 'Отмена',
        nextStep: 'Следующий шаг',
        delete: 'Удалить',
        modify: 'Изменить',
        newAddressPlaceholder: 'Новый адрес',
        addressMaxCountTip:
          'В настоящее время вы можете добавить до {maxCount} адресов получения в субсчет. Если вам нужна поддержка других адресов, обратитесь в службу поддержки клиентов support@connectbtc.com.',
        updateBitconAddressTip:
          'В целях сохранности средств платеж будет приостановлен в течение 48 часов после этой операции, а ваш полученный доход будет выплачен в первый расчетный день после 48 часов.',
        emptyRemark: 'Примечания не добавлены',
        noAddressForAdjust:
          'Вы не добавили ни одного адреса. Пожалуйста, сначала добавьте несколько адресов, прежде чем настраивать коэффициент или порог.',
        balanceMoveTip:
          'Неоплаченная криптовалюта текущего субаккаунта будет переведена на «Новый адрес 1», пожалуйста, подтвердите свою операцию.',
        confirmModalTitle: 'Проверка безопасности',
        authentication: 'Аутентификация',
        operationConfirm: 'Подтвердить операцию',
        addAddressConfirmTip: 'Вы добавляете {count} адресов',
        modifyProportionConfirmTip:
          'Вы изменяете пропорцию каждого адреса получения',
        deleteAddressConfirmTip: 'Вы удаляете адрес <br/>{address}',
        selectBalanceMoveAddress: 'Выберите адрес наследования',
        selectBalanceMoveAddressTip:
          'Неоплаченная криптовалюта удаляемого адреса составляет {balance}{coinType}. После этой операции он будет переведен на новый адрес, выбранный выше, в течение 24 часов.',
        cannotDeleteAddress: 'Невозможно удалить адрес',
        deleteAddressRule:
          'Удалять можно только адреса с долей выплат, равной 0,00%.',
        // deleteAddressRuleDetail:
        //   '1.Доля равна 0,00%;<br/>2.Адрес интеллектуального майнинга той же субсчетной записи не указан как этот адрес.',
        // usedBySmart:
        //   'Интеллектуальный майнинг текущей субсчетной записи выбирает текущий адрес, поэтому эта модификация одновременно изменит адрес майнинга пулемета.',
        operationSuccess: 'Успешная операция',
        validate: {
          emptyAddress: 'Пожалуйста, введите новый адрес',
          emptyAddressForUpdate: 'Пожалуйста, введите новый адрес',
          emptyProportion: 'Заполните пропорцию адреса ',
          emptyProportioAfter: '',
          proportionInvalidate:
            'Доля каждого адреса должна быть больше или равна 0%, а сумма всех пропорций должна быть равна 100%',
          maxCount:
            'Для каждой дополнительной учетной записи можно установить до 20 адресов.',
          duplicateAddressPrefix:
            'Один и тот же адрес можно добавить только один раз, но адрес ',
          duplicateAddressSuffix: ' добавляется повторно.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },
      //min pay
      currentMinPay: 'Текущая мин. сумма оплаты:',
      minPayAllSubAccount:
        'Для всех субаккаунтов BTC используется одна мин. сумма оплаты',

      //Share to watcher
      watcher: 'Наблюдатель',
      availableWatchers: 'Текущий баланс',
      watcherExpress:
        '* Наблюдатель может посмотреть Ваши данные по ссылке ниже, но не может выполнять управление или изменения。',
      createWatcher: 'Создать нового наблюдателя',
      modifyWatcher: 'Изменить права',
      authoritySettings: 'Настроить права',
      setWatcherName: 'Указать имя примечания для наблюдателя',
      watcherName: 'Имя примечания для наблюдателя',
      watcherNameTip: 'Макс. символов: 2–20',
      notEmptyWatcherNote: 'Имя примечания наблюдателя не может быть пустым',
      tips: 'Советы:',
      tip_dashboard:
        '1.«Личный кабинет» не включает такую информацию о доходах, как «Неоплаченные» и «Выплаченные»;',
      tip_earning:
        '2. «Прибыль» включает такую информацию, как «Неоплаченные» и «Выплаченные» для каждого адреса.',
      moreSettings: 'Другие настройки',
      guardianRemark:
        'Вы можете делиться информацией о субаккаунте (включая вычислительную мощность, майнеры и др.) частично или полностью с другими пользователями. Информация доступна только для просмотра, изменение невозможно',
      guardian: 'Поделиться аккаунтом',
      shareAccountsSetting: 'Доля аккаунта',
      accessKey: 'Access Key',
      generateAK: 'Создать Access Key',
      puid: 'PUID',
      apiDoc: 'API Документ',
      confirmDeleteWatcher: 'Удалить аккаунт этого наблюдателя?',
      watcherAuths_Dashboard: 'Пользовательская панель',
      watcherAuths_Dashboard_Tip: '(Не содержит данные дохода)',
      watcherAuths_Miners: 'Страница майнера',
      watcherAuths_Earnings: 'Данные дохода',
      watcherAuths_GetCoin: 'Подарок',

      // Alert setting
      alertItem: 'Настройка сигнализации',
      alertContacts: 'Контактное лицо при сигнализации',
      hashrateAlert: 'Сигнализация Хешрейт',
      minerAlert: 'Сигнализация количества майнеров',
      alertFrequency: 'Частота сигнализации',
      alertFrequencyTip:
        'По политическим причинам частота SMS-оповещений в некоторых странах и регионах фиксирована: один раз каждые 24 часа. Настоящим уведомляется об этом.',
      warnMeWhenHashrate: 'Предельное сигнальное значение Хешрейт≤',
      warnMeWhenActiveMiner:
        'Предельное сигнальное значение кол-ва активных майнеров≤',
      dontWarnMeTwice: 'Мин. промежуток между сигнализацией',
      hours: 'ч.',
      apply: 'Применить',
      operateFailed: 'Сбой операции!',
      contactsList: 'Список контактных лиц',
      cancel: 'Отмена',
      ok: 'Подтвердить',
      save: 'Сохранить',
      reset: 'Сброс',
      newContact: 'Добавить контактное лицо',
      editContact: 'Изменить контактное лицо',
      deleteContact: 'Удалить контактное лицо',
      confirmDeleteContact: 'Удалить это контактное лицо сигнализации?',
      name: 'Обращение/примечание',
      phone: 'Номер телефона',
      email: 'Эл. почта',
      validateAlert: {
        nameEmpty: 'Обращение/примечание не может быть пустым',
        phoneRegionFormat: 'Некорректный формат международного кода',
        phoneFormat: 'Некорректный формат номера телефона',
        emailFormat: 'Некорректный формат эл. почты',
        contactEmpty: 'Необходимо заполнить эл. почту или номер телефона',
      },
      max_email_contacts: 'Макс. адресов эл. почты контактных лиц: 10',
      max_phone_contacts: 'Макс. телефонов контактных лиц: 5',
      enabled_alert_need_hashrate:
        'Хешрейт не обнаружена. Невозможно включить сигнализацию。',
      alertItemUpdateSuccess: 'Элементы сигнализации настроены',

      done: 'Отправить',

      okay: 'Подтвердить',
      emptyAddress: 'Адрес не может быть пустым',
      invalidAddress: 'Некорректный адрес',
      emptyCode: 'Код подтверждения не может быть пустым',
      wrongCode: 'Ошибка кода подтверждения',
      emailVia: 'Отправить эл. письмо',
      textVia: 'Отправить SMS-сообщение',
      help: 'Справка',

      contact: 'Контакты:',
      close: 'Закрыть',
      receivedress: 'Адрес получения:',

      todayUpdateMinPay:
        'С сегодняшнего дня мин. сумма оплаты для дохода от майнинга изменена на:{minPay}',
      updateAddress: 'Время изменения адреса:',
      // pay with btc
      payWithBtcTip:
        '* После включения расчетов в BTC, Ваши доходы от майнинга будут конвертироваться в Bitcoin в определенное время каждый день и будет отображаться в записях о доходах. Выплата будет осуществлять на отдельный адрес расчетов BTC (обычно в 12:00 каждый день). В особых ситуациях Вы будете уведомлены。',
      convertedCurrency: 'Валюта обмена: ',
      setBtcAddressTip: 'Укажите адрес получения BTC',
      btcAddress: 'Адрес Bitcoin: ',
      conversionRate: 'Примерная ставка',
      convert: 'Обменять',
      getValue: 'Вы получите ',
      conversionTip:
        '* Примерная ставка представлена только для справки. Ориентируйтесь на фактическое значение.',
      lastUpdate: 'Последнее обновление: ',
      on: 'Вкл.',
      off: 'Выкл.',
      turnOnPaywith: 'Включить оплату в BTC?',
      turnOffPaywith: 'Выключить оплату в BTC?',
      payOn: 'Включить',
      payOff: 'Выключить',
      resend: 'Отправить повторно',
      send: 'Отправить',
      resendTip:
        'Если вы не получили код, подождите немного или попробуйте еще несколько раз.',

      // Изменить в одно нажатие One Switch
      oneSwitch: {
        attention: 'Будьте внимательны',
        optionCurrentMode: 'Выберите режим переключения',
        switch: 'Хешрейт будет переключена на {destMode}',
        switchAnotherMining: 'Выполняется переключение на режим {destMode}...',
        ethSwitchWarning:
          'Внимание. Если Вам майнер не поддерживает DAG, доход может быть равен 0. Необходимо вручную перезапустить майнер, чтобы восстановить нормальное функционирование. (Известно, что не поддерживается муравей-майнер E3, при переключении ETC на ETH, необходимо перезапустить вручную)',
        ethSwitchWarningLinkTxt: 'Нажмите, чтобы узнать подробнее',
        hashsHaveSwitched: 'Хешрейт переключена на майнинг {destMode};',
        checkSubAccount:
          'Проверьте субаккаунт {subAccountName}- {subAccountRegionNode}',
        enter: 'Войти',
      },
      // Адрес получения умный бассейн
      smartAddress: {
        autoSwapSettlement: 'Быстрый расчет',
        original: 'Расчет исходная валюты',
        autoTip:
          'Доход валюты умный бассейн рассчитывается ежедневно после обмена на целевую валюту',
        originalTip:
          'Доход умный бассейн рассчитывается в валюте BTC, BCH или BSV',
        originalCurrency: 'Исходная валюта',
        targetCurrency: 'Целевая валюта',
        earningsInSmartPool: 'Доход умный бассейн',
        activated: 'Включен',
        unactivated: 'Выключен',
        modifyTip:
          'Целевая валюта быстрого расчета умный бассейн связана и совпадает с адресом получения для аналогичной валюты под одним субаккаунтом',
        autoHistory: 'Обменять архивные доходы?',
        notice1:
          'В целях безопасности платежи не проводятся в течение 48 часов после изменения адреса. Ваш доход будет зачислен на баланс и выплачен в первый расчетный день через 48 часов。',
        notice2:
          'После включения функции быстрого обмена при расчете за текущий день доход от майнинга будет автоматически обменен в целевую валюту. Нажимая OK, Вы выражаете согласие{fundLink}。',
        notice3:
          'Адрес в интеллектуальном режиме связан с единственным адресом монеты в той же дополнительной учетной записи и соответствует ему (если установлено несколько адресов, он будет согласован с первым адресом)',
        originalNotice1:
          'Адрес в интеллектуальном режиме связан с единственным адресом монеты в той же дополнительной учетной записи и соответствует ему (если установлено несколько адресов, он будет согласован с первым адресом)',
        currentPayoutMode: 'Текущий режим расчета: {mode}',
        fundLink: `《Соглашение о передаче прав на валютные обмены CloverPool》`,
        originBtn: 'Включить расчет исходной валюты',
        otcBtn: 'Включить быстрый расчет',
        otcSuccess:
          'В режиме умный бассейн включена функция быстрого обмена расчета. BTC используется в качестве валюты доходов.',
        otcModalNotice2: `После включения быстрого обмена архивных доходов для соответствующего дохода будет доступен быстрый обмен. Если такая функция не включена, быстрый обмен будет применен только к доходам, полученным недавно.`,
        originSuccess:
          'В режиме умный бассейн включена функция расчета исходной валюты. В качестве валюты выплаты дохода будет использоваться реальная валюта майнинга。',
      },
    },
    homepage: {
      btcSmartAgent: 'Интеллектуальный агентский сервис BTC',
      agentExpress:
        'Эффективный и прозрачный&emsp;&emsp;&emsp; Доступные для просмотра майнеры',
      bit64: 'Win7 и выше, х64',
      bit32: 'WinXP, х32',
      document: 'Справочные документы для Windows',
      accessLinuxVersion: 'Прямой доступ к документам по установке для Linux',
      cuttingEdgeTechnology: 'Передовые ключевые технологии',
      latestArchitecture:
        'Новейшая оригинальная архитектура<br/>Отсутствие аналогов',
      openSourceCode: 'Открытый код<br/>Открытость и честность',
      highTestPressure: 'Сверхмощное тестирование<br/>Стойкость и надежность',
      lowOrphanedRate: 'Сверхнизкая потеря блоков<br/>Молниеносная скорость',
      transparentAgent: 'Новейшие программы<br/>Прозрачный агентский сервис',
      standardsServices: 'Новый стандарт майнинга',
      customerHotline: 'Служба поддержки по телефону',
      highHashrateConsultation: 'Поиск крупных партнеров',
      professionalCustomerService:
        'Майнинг-пул с профессиональной командой службы поддержки',
      bestVipServer: 'Самый масштабный VIP сервер в отрасли',
      oneToOne:
        'Индивидуальные примеры анализа от ведущих исследователей отрасли',
      appAvailable:
        'С приложением для майнинг-пула Вы можете управлять всем лежа',
      earningsTimelyEveryday: 'Точный перевод средств Без рисков и преград',
      transnationalTeamOperation: 'Первый майнинг-пул с международной командой',
      currencies: 'Тип валюты',
      dailyEarnings: 'Дневной доход',
      hashrate: 'Хешрейт',
      hashrateChart: 'График хешрейта',
      explorer: 'Браузер',
      prices: 'Курс валюты',
      earningsModels: 'Режим дохода',
      minPayment: 'Мин. сумма оплаты',
      help: 'Справка',
      notePaytime: 'Ежедневно 0:00-8:00 (UTC) для выплаты вчерашнего дохода',
      yuanKwhElectric: 'юань/кВт⋅час (электричество)',
      todayEarnings: 'Дневной доход ок.:',
      piece: 'шт.',
      signIn: 'Войти',
      signUp: 'Регистр',
      power: 'мощность',
      hashrateProfitEst: 'Хешрейт Прибыль Расчет.',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: 'Пример дохода популярного майнера',
      fee: 'Тариф',
      desc: 'Ведущий универсальный в мире майнинг-пул с поддержкой широкого набора валют',
      tutorial: 'Обучение',
      diff: 'Сложность',
      nextDifficultyEstimated: 'Следующая сложность',
      dateToNextDifficulty: 'Регулировка сложности',
      Algorithm: 'Алгоритм',
      backup: 'Запасный порт',
      whyMe: {
        title: 'Почему стоит выбрать CloverPool?',
        leadingTechnology: 'Передовые технологии',
        convenientManagement: 'Удобное управление',
        revenueAssurance: 'Гарантий дохода',
        professionalServices: 'Профессиональный сервис',
        desc1: `Открытый код, бесплатная программа для управления майнером, официальная  персонализированная аппаратура с высокой частотой, обслуживание данных и технологий блокчейна.`,
        desc2:
          'Простое управление через приложение и на веб-странице, быстрая регистрация аккаунта с помощью телефона/эл. почты, собственная функция сигнализации, адрес получения дохода, группировка майнеров и ссылки для совместного управления данными с наблюдателями.',
        desc3: `Реальные и прозрачные данные майнинга, продвинутая модель дохода FPPS, мощный расчетный центр, своевременная оплата, в дождь и в ясную погоду.`,
        desc4: `В CloverPool работает профессиональная команда по обслуживанию клиентов, предлагаются персонализированные VIP-услуги высочайшего уровня, а ведущие исследователи анализируют случаи из практики в отрасли индивидуально.`,
      },
      app: {
        title: ' App',
        description:
          'Ведущий универсальный в мире майнинг-пул с поддержкой широкого набора валют',
        property1:
          'Поддержка широкого набора валют для майнинга, управление несколькими субаккаунтами, простое управление за счет отправки ссылок наблюдателям',
        property2:
          'Отображение всех данных валют в реальном времени, быстрое реагирование на динамику майнинга',
        property3:
          'Новый пользовательский интерфейс для повышения качества и скорости работы',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'Режим расчета ETH: двойной, FPPS/PPS+. Текущий режим: FPPS. Платформа регулирует режим расчетов на основе совокупности факторов: операционных расходов, доля вычислительной мощности майнинг-пула, рыночной ситуации и дохода пользователя.',
            'PPS+':
              'Режим расчета ETH: двойной, FPPS/PPS+. Текущий режим: PPS+. Платформа регулирует режим расчетов на основе совокупности факторов: операционных расходов, доля вычислительной мощности майнинг-пула, рыночной ситуации и дохода пользователя。',
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'Оборудование, которое занимается только добычей ETC, подключается к этому майнинг-адресу.',
      zilMinerAddressTips:
        'К этому адресу майнинга подключено оборудование для двойного майнинга ETC и ZIL.',
    },
    advanceAccount: {
      title: 'Управление субаккаунтом - CloverPool',
      all: 'Все',
      unit: 'шт.',
      subAccount: 'Субаккаунт',
      coin: 'Тип валюты',
      hashrate: 'Хешрейт',
      allMiners: 'Все',
      guard: 'Имя примечания',
      activeMiners: 'Активный',
      inactiveMiners: 'Неактивный',
      deadMiners: 'мертвых',
      region: 'Регион',
      more: 'Еще',
      setting: 'Настройки',
      totalHashrate: 'Общая Хешрейт',
      search: 'Поиск',
      status: 'Статус',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: 'С сегодняшнего при майнинге (Bitcoin Cash, Bitcoin) в майнинг-пуле CloverPool можно получить в подарок NMC, Elastos (Elastos).',
        bch: 'С сегодняшнего при майнинге (Bitcoin Cash, Bitcoin) в майнинг-пуле CloverPool можно получить в подарок NMC, Elastos (Elastos).',
        ltc: 'С сегодняшнего при майнинге (Litecoin) в майнинг-пуле CloverPool можно получить в подарок Doge, способ расчета PPLNS。',
        bcha: `<p>BCHA（BCHABC）Форк BCH, посетите, чтобы узнать подробнее <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a>。</p>
          <p><b>Время вывода:</b><br/>08:00 7 декабря 2020 г. До 08:00 7 января 2021 г. (по сингапурскому времени)
          </p>`,
      },
      beta: 'Внутренняя проверка',
      whatsThis: 'Что это?',
      address: 'Адрес:',
      newAddress: 'Новый адрес',
      addressComplete:
        'Во избежание потерь заполните адрес для подарочных валют как можно скорее。',
      bchaAddressComplete:
        'После 7 января 2021 г. BCHA пользователей, не указавших адрес, будут удалены без возможности восстановления. Во избежание потерь заполните адрес для подарочных валют как можно скорее。',
    },
    watcher: {
      expired: 'Срок действия прав наблюдателя истек',
      incorrect: 'Ошибка ссылки наблюдателя',
    },
    activity: {
      pointsToday: 'Баллы сегодня',
      pointsSum: 'Всего баллов',
      ranking: 'Рейтинг',
      earnings: 'Доход',
    },
    minerRanking: {
      title: 'Оценка добычи',
      electric: 'Заряд / день',
      dailyEarning: 'Дневной доход',
      priceShutdown: 'Цена выключения',
      ALL: 'Все',
      CNY: 'юань CNY',
      USD: 'долл. США USD',
      desc: 'Сортировать по убыванию',
      asc: 'Сортировать по возрастанию',
      electricPercent: 'Коэффициент комиссии',
      searchTip: 'Укажите майнер',
      updated: 'Обновлено',
      coin: 'Валюта',
      emptyShow: 'Нет майнеров, отвечающих условиям',
      rank: 'Рейтинг',
      miner: 'Майнер',
      power: 'мощность',
      hashrate: 'Хешрейт',
      rev24h: 'Ежедневный выход',
      energy: 'Расход на электричество',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
