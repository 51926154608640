import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'Français',
    header: {
      home: 'Accueil de Pool',
      dashboard: 'Tableau de Bord',
      getCoin: 'Obtenir des Pièces',
      help: 'Aide',
      miners: 'Mineurs',
      poolStats: 'Stats de Pool',
      commissionEarnings: 'Gains de commissions',
      miningEarnings: 'Bénéfices miniers',
      service: 'Service',
      tools: 'Outils',
      setting: 'Paramètres',
      guardian: 'Partage',
      guardianPermission:
        "Vous êtes un utilisateur autorisé disposant d'un accès en lecture seule pour le compte.",
      watcher: 'Surveillant',
      watcherPermission:
        "Le Surveillant est un utilisateur autorisé disposant d'un accès en lecture seule pour le sous-compte.",
      userCenter: "Centre d'Utilisateur",
      SignOut: 'Déconnexion',
      accountSetting: 'Paramètres de Compte',
      poolManagePanel: 'Tableau de bord',
      watcherMode: 'Mode Surveillant',
      scanToService: "Scannez le code QR pour obtenir de l'aide",
      bitdeer: 'Bitdeer',
      hashnest: 'HashNest',
      explorer: 'Blockchain Explorer',
      app: 'App',
      getBCHA: 'Retrait BCHA',
      candy: 'Candy',
      alertBar:
        '<b>Le 10 août, de 22h à 24h (UTC+8)</b>, le pool minier CloverPool effectuera une maintenance des données à grande échelle. Certaines fonctions seront indisponibles et le service de minage ne sera pas affecté. <a href="https://help.cloverpool.com/hc/en-us/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">Cliquer pour plus de détails</a>',
      introTip:
        'Les fonctions telles que le Tableau de Bord, les Mineurs, les Revenus et les Paramètres de Sous-Compte sont unifiés dans le Tableau de Bord de Pool.',
    },
    nav: {
      'nav-explorer': 'Explorateur',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Piscine',
      'nav-pool-home': 'Accueil de Pool',
      'nav-pool-data': 'Données de Pool',
      'nav-pool-candy': 'Candy',
      'nav-pool-tools': 'Outils & Logiciel',
      'nav-pool-cs': 'Service Client',
      'nav-wallet': 'Portefeuille',
      'nav-help': 'Aide',
      'nav-navigation': 'La navigation',
      'nav-inscriptions': 'Inscrire',
      'nav-app': 'APP',
      'nav-collaborate': 'Coopération',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Outil',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'Solde Actuel',
      coinAddress: 'Adresse de {coinType}',
      setAddress: 'Veuillez définir une Adresse de {coinType}.',
      multAddressEnabled: 'Le paiement multi-adresse est activé',
      menu: {
        hide: 'Masquer',
        address: 'Adresse de Portefeuille',
        minPay: 'Paiement Minimum',
        payWithBtc: 'Payer avec BTC',
        earingsHistory: 'Revenus',
        shareData: 'Partage de Compte',
        watcher: "Définir l'observateur",
        apikey: 'API KEY',
        alertSettings: 'Définir une alerte',
        onebuttonSwitch: 'Interrupteur à un Bouton',
        userCenter: "Centre d'Utilisateur",
        signOut: 'Déconnexion',
        delete: 'Supprimer',
      },
      confirm: 'OK',
      cacel: 'Annuler',
      deleteConfirm: 'Confirmation de Suppression',
      cannotDelete: 'Impossible de supprimer le sous-compte',
      cannotDeleteView:
        'Impossible de supprimer le sous-compte en cours de consultation',
      deleteCondition:
        'Impossible de supprimer le sous-compte <span class="subAccountName">{{accountMessage}}</span>. Seuls les sous-comptes qui remplissent les deux conditions suivantes peuvent être supprimés:',
      cannotDeleteReason:
        "1. Le taux de hachage du sous-compte au cours des 180 derniers jours est 0.<br/>2. La crypto-monnaie non payée du sous-compte (y compris les pièces cadeaux) est 0.<br/>3.Le sous-compte n'appartient à aucun type de sous-compte spécial.",

      deleteName: 'Vous supprimez le sous-compte:',
      deleteInfo:
        "* Après avoir cliqué sur OK, le sous-compte sera définitivement supprimé et ne pourra pas être récupéré.<br/>* Après la suppression, le taux de hachage, l'historique de revenu et d'autres données du sous-compte ne peuvent pas être récupérés.",
      deleteSuccess: 'Sous-compte supprimé',
    },
    footer: {
      services: 'Services',
      Products: 'Produits',
      help: 'Aide',
      btcTool: 'BTC Tools',
      btcSmartAgent: 'BTC Smart Agent',
      btcComFirmware: 'Firmware CloverPool',
      blockchainExplorer: 'Blockchain Explorer',
      app: 'App',
      apiDocs: 'Docs API',
      vipApplication: 'Candidature VIP',
      cloudMinePoolService: ' Service de Construction de Pool Minier en Nuage',
      miningTutorial: 'Tutoriel de Minage',
      FAQ: 'FAQ',
      Announcements: 'Annonce',
      ticketSystem: 'Système de Tickets',
    },
    pageTitle: {
      home: 'CloverPool - Le premier pool minier multidevises complet au monde',
      dashboard: 'Tableau de Bord - CloverPool',
      miners: 'Mineurs - CloverPool',
      earningHistory: 'Historique de Revenus - CloverPool',
      earningHistoryForInvite: 'Gains de commissions - CloverPool',
      poolStats: 'Données de Pool - CloverPool',
      guardian: "Lien de l'Invitation de Tuteur",
      guardianBind: "Liaison du Lien de l'Invitation de Tuteur - CloverPool",
      subAccount: 'Créer un sous-compte - CloverPool',
      subAccountManage: 'Gestion de Sous-Compte - CloverPool',
      settings: 'Paramètres - CloverPool',
      getCoin: 'Obtenir des Pièces - CloverPool',
      tools: 'Outils & Logiciel - CloverPool',
      bitdeer: 'Bitdeer - CloverPool',
      watcherExpired: 'Autorité de Surveillant expirée - CloverPool',
      watcherIncorrect: 'Erreur de lien de Surveillant - CloverPool',
      fundAuthorization: "Accord d'Autorisation de Fonds - CloverPool",
      app: "Téléchargement d'App - CloverPool",
      invite: 'Invitation Reward - CloverPool',
    },
    breadCrumbs: {
      home: 'Accueil',
      dashboard: 'Tableau de Bord',
      miners: 'Mineurs',
      earningHistory: 'Revenus',
      poolStats: 'Données de Pool',
      shareAccount: 'Paramètres de Partage de Compte',
      guardianBindingInvitation: "Liaison du Lien de l'Invitation de Tuteur",
      guardianBindingLink: 'Gestion du Compte de Tuteur',
      subAccountManage: 'Gestion de Sous-Compte ',
      hiddenSubAccounts: 'Gestion de Sous-Comptes Masqués',
      settings: 'Paramètres',
      getCoin: 'Obtenir des Pièces',
      tools: 'Outils & Logiciel',
      fundAuthorization: "Accord d'Autorisation de Fonds ",
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: 'Pool Intelligent',
      otcNotice: `Vous êtes sur le point de passer votre taux de hachage en Pool Intelligent. Cliquez sur OK sera considéré comme avoir lu et accepté: {fundLink}`,
      fundLink: `Contrat de service de Pool Intelligent `,
      autoTip: `L'utilisation du Pool Intelligent signifie que vous hébergez le taux de hachage sur CloverPool, qui basculera entre BTC, BCH et BSV avec le plus de profit à exploiter.`,
      originalTip:
        "Si vous choisissez le mode de devise d'origine, le hachage de SHA256 exploitera uniquement la pièce que vous avez sélectionnée au lieu de basculer automatiquement.",
    },
    operation: {
      name: 'Opération',
      success: 'Opération réussie!',
      failed: 'Opération échouée!',
    },
    earning: {
      balanceTip:
        "Une fois le règlement terminé, nous effectuerons généralement le paiement dans les 24 heures, sauf dans les situations suivantes :<br>1.Aucune adresse n'est définie ;<br>2.L'adresse est définie mais le montant cumulé du règlement n'a pas atteint le seuil de paiement minimum ;<br>3.Des opérations telles que l'ajout d'une adresse, la modification d'une adresse, la suppression d'une adresse ou la modification d'un ratio ont déclenché la règle de suspension de paiement de 48 heures ;<br>4.La règle de contrôle des risques est déclenchée ;<br>5.La monnaie subit un hard fork, ou rencontre une attaque à 51%, ou d'autres améliorations ou accidents majeurs.",
    },
    meta: {
      keywords:
        'Bitcoin,BTC,BCH,Bitcoin Cash,LTC, Litecoin,ETC,KAS,Kaspa,via,viabtc,f2pool,antpool,foundry,binance,luxor,poolin,pool,miners,worker,machine,tools,mint,brc20,runes,cloverpool,bitdeer,bitmain',
      description:
        "CloverPool est un tout nouveau choix pour les mineurs, qui prend en charge l'extraction de Bitcoin, Litecoin, Bitcoin Cash, Kaspa et d'autres crypto-monnaies. Avec une expérience utilisateur bien meilleure, des frais bien inférieurs et un service bien plus performant.",
    },
    gotIt: 'Compris',
    showAll: 'Afficher Tout',
    foldMe: 'Masquer Tout',
    copied: 'Copié',
    more: 'Plus',
    none: 'Aucun',
    on: 'Activé',
    off: 'Désactivé',
    all: 'TOUT',
    modify: 'Modifier',
    submit: 'Envoyer',
    ok: 'OK',
    cancel: 'Annuler',
    new: 'Ajouter',
    address: 'Adresse',
    notFoundTip: "Désolé, la page que vous recherchez n'existe pas",
    export: 'Exportation',
    startDate: 'Date de Début',
    endDate: 'Date de Fin',
    smartPool: 'Pool Intelligent',
    status: 'État',
    notice: 'Avis',
    yes: 'Oui',
    no: 'Non',
    coin: 'Pièce',
    custom: 'Personnalisé',
    hide: 'Masquer',
    less: 'Moins',
    details: 'Des détails',
    region: {
      cn: 'Pékin (Chine)',
      sz: 'Shenzhen (Chine)',
      us: 'USA',
      eu: 'EU',
      sg: 'Singapour',
    },
    LegalStatement:
      "J'affirme par la présente que: je ne suis pas un résident de Cuba, de l'Iran, de la Corée du Nord, de la Syrie, de la Russie ou d'autres pays ou régions soumis à des sanctions administrées ou appliquées par les pays, gouvernements ou agences internationales concernés, et je ne suis pas résident de la Chine continentale. Le service de pool minier fourni par CloverPool est conforme aux lois, réglementations et politiques connexes du pays ou de la région où je me trouve.",
    LegalStatementConfirm:
      "Tous les risques et responsabilités juridiques causés par ou découlant du fait qu'il est illégal d'utiliser les services fournis par CloverPool dans le pays ou la région où je vis seront à ma charge exclusive.",
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
