import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} Mining-Einnahmen',
      accountEarning: 'Kontoeinnahmen',
      active: 'Aktiv',
      activeMiner: 'Aktiv',
      amoutPaid: 'Gesamter bezahlter Betrag',
      announcement: 'Ankündigung',
      cannotConnect: 'Verbindung nicht möglich?',
      urlConfigGpu: ' unterstützt normalen GPU-Miner',
      urlConfigNicehash: ' optimiert für Nicehash',
      urlConfigGpuDcr: ' unterstützt Antminer DR3, Claymore, gominer',
      urlConfigNicehashDcr: ' unterstützt Nicehash, Innosilicon miner',
      customerTitle: 'Kundendienst',
      dailyHashrate: 'Täglich',
      dashboard: 'Dashboard',
      earnMore: 'Wie viel habe ich mehr verdient?',
      earning: '{coinType} Einnahmen',
      eventConnected: 'verbunden',
      eventDisconnected: 'getrennt',
      eventMiner: 'Miner',
      eventName: 'Event',
      eventSkip: 'Überspringen',
      eventTitle: 'Events',
      eventlatestLogs: 'Die folgenden Events sind abgelaufen',
      hastrateChart: 'Hashrate-Diagramm',
      history: 'Verlauf',
      hour: '{n} Std.',
      hours: 'Stunden',
      inActive: 'Inaktiv',
      lastPaymentTime: 'Letzte Zahlungszeit',
      markAllRead: 'Alles als gelesen markieren',
      minerContent:
        'Greifen Sie auf das lokale Netzwerk zu und loggen Sie sich in das Hintergrundsystem der Miner ein, geben Sie den Miner-Namen ein und speichern Sie das Profil. Die Miner werden in wenigen Sekunden zum CloverPool hinzugefügt.<br/>Miner-ID-Einstellungen: {sub_account}.001, {sub_account}.002 usw.; jede Miner-ID entspricht einem Miner.',
      minerNameStand: `Standardname des Miners: Unterkonto+'.'+Zahl, wenn Ihr Unterkonto beispielsweise {sub_account} ist, dann könnten Ihre Miner als {sub_account}.001, {sub_account}.002 eingerichtet werden. Jeder Miner hat seinen eigenen Namen.`,
      minerName:
        '* Setzen Sie &lt;sub-account&gt;.&lt;miner_ID&gt; auf Ihren Minern, z.B. {sub_account}.001, {sub_account}.002, etc.',
      miner: 'Miner',
      miners: 'Miner',
      miner1: 'Typ-I-Miner',
      miner2: 'Typ-II-Miner',
      miningAddress: 'Mining-Adresse',
      miningEarnings: '{coinType} Mining-Einnahmen',
      minute: 'Min.',
      more: 'Mehr',
      networkDiff: 'Netzwerk-Schw.',
      networkHashrate: 'Netzwerk-Hashrate',
      networkStatus: 'Netzwerkstatus',
      nextDifficult: 'Geschätzte nächste Schwierigkeit',
      noMiner:
        'Fügen Sie Miner zum Pool von CloverPool hinzu und beginnen Sie mit dem Mining. Empfohlen wird die Verwendung von ',
      btcTools: 'BTC-Tool',
      noEvent: 'Keine Eventbenachrichtigung!',
      nowHashrate: 'Echtzeit',
      pendingPayouts: 'Ausstehende Zahlung',
      poolHashrate: 'Pool-Hashrate',
      realHashrate: '{coinType} Hashrate',
      realtime: 'Echtzeit',
      timeRemain: 'Verbleibende Zeit',
      title: 'Dashboard - CloverPool',
      today: 'Heutige Schätzung',
      unpaid: 'Guthaben',
      yesterday: 'Gestern',
      yesterdayEarnTip:
        'Bezieht sich auf die Einnahmen von 0:00 bis 24:00 Uhr am Tag vor der UTC-Zeit.',
      todayEarnTip:
        'Das geschätzte Einkommen der Hashrate, beim ausschließlichen Minen von BTC (der Algorithmus von Sha256)',
      charts: {
        hashrate: 'Hashrate',
        minerCharts: 'Miner-Hashrate-Diagramme',
        myCharts: 'Meine Hashrate-Diagramme',
        poolCharts: 'CloverPool Hashrate-Diagramme',
        reject: 'Abgelehnt',
        time: 'Zeit',
      },
      yesterday_earn: 'Gestrige Einnahmen',
      yesterday_hashhrate: 'Gestern akzeptierte Hashrate',
      earnings_per_100T: 'Einnahmen pro 100T',
      //职责声明
      statement:
        '* Es ist Ihnen nicht gestattet, auf diesen Dienst zuzugreifen (oder zu versuchen, darauf zuzugreifen), außer durch die von uns bereitgestellten oder angegebenen Mittel, und Sie stimmen ausdrücklich zu, nicht auf diesen Dienst mittels illegaler oder rechtswidriger Mittel zuzugreifen (oder zu versuchen, darauf zuzugreifen) oder diesen Dienst zu nutzen, um illegale oder rechtswidrige Aktivitäten oder Zwecke zu unterstützen. Indem Sie diese Webseite betreten und sich für diesen Dienst anmelden, erkennen Sie an und stimmen zu, dass Sie eine Person sind, der dieser Dienst gemäß den Gesetzen Ihres Wohnsitzes rechtmäßig zur Verfügung gestellt werden kann.',
      // 收益预估
      estimatedEarnings: {
        title: 'Geschätzte Einnahmen',
        hours24Earnings: 'Geschätzte Einnahmen in den letzten 24 Stunden',
        tip24:
          'Basierend auf dem durchschnittlichen Schwierigkeitsgrad der Währung in den letzten 24 Stunden',
        realTimeEarnings: 'Geschätzte Echtzeiteinnahmen',
        realTimeEarningsTip:
          'Basierend auf der neuesten Mining-Schwierigkeit der Währung',
      },
    },
    subAccount: {
      title: 'Unterkonto erstellen - CloverPool',
      createWorker: 'Unterkonto erstellen',
      noNode: 'Sie haben den Node noch nicht ausgewählt!',
      noCoinType: 'Sie haben den Mining-Coin noch nicht ausgewählt!',
      selectRegion: 'Wählen Sie Ihre Region',
      setWorker: 'Einen Unterkontonamen festlegen',
      enterWorker: 'Geben Sie den Namen des Unterkontos ein',
      enterAddress: 'Adresse eingeben',
      coinTypeEx:
        'CloverPool unterstützt jetzt das Minen von {supportCoins}, auch andere Coins werden bald verfügbar sind, freuen Sie sich darauf.',
      regionEx:
        'CloverPool stellt Server auf der ganzen Welt bereit und der nächstgelegene Server bietet Ihnen die beste Erfahrung.',
      workerEx:
        'Dieses Unterkonto wird an die obige Region gebunden. Sie können bis zu 2.000 Unterkonten erstellen. Legen Sie <sub-account>.<miner_name> für Ihre Miner fest, z.B. subaccount.001, subaccount.002 usw.',
      workerCaution:
        '* Achtung: Jedes Unterkonto kann nur in einer Region minen. Dieses Unterkonto, das Sie erstellen, kann nicht in anderen Regionen verwendet werden. Wenn Sie in einer anderen Region minen möchten, erstellen Sie bitte ein weiteres Unterkonto in einer anderen Region.',
      setAddress: 'Eine Auszahlungsadresse festlegen',
      save: 'Erstellen und Mining',
      verifyWorker: '3-20 Buchstaben oder Zahlen',
      option: 'Optional',
      optional:
        'Optional. Die BCH-Adresse ähnelt der von BTC. Bitte achten Sie darauf, dass Sie nicht die falsche Adresse eingeben.',
      'optional-BTC':
        'Optional. Die BCH-Legacy-Adresse ähnelt der von BTC. Bitte achten Sie darauf, dass Sie nicht die falsche Adresse eingeben',
      'optional-BCH':
        'Optional. Die BCH-Legacy-Adresse ähnelt der von BTC. Bitte achten Sie darauf, dass Sie nicht die falsche Adresse eingeben. Es wird empfohlen, eine neue Adresse zu verwenden.',
      'optional-UBTC':
        'Optional. Die BCH-Legacy-Adresse ähnelt der von UBTC. Bitte achten Sie darauf und geben Sie nicht die falsche Adresse ein.',
      'optional-SBTC':
        'Optional. Die BCH-Legacy-Adresse ähnelt der von SBTC. Bitte achten Sie darauf und geben Sie nicht die falsche Adresse ein.',
      'optional-coin':
        'Optional: Bitte achten Sie darauf, dass Sie nicht die falsche Adresse eingeben.',
      selectCoinType: 'Wählen Sie Ihren Mining-Coin',
      automatic: 'Automatisch',
      recommandWallet: 'Wir empfehlen Ihnen, CloverPool Wallet zu verwenden',
      withdrawToExchange: 'Auszahlungshilfe',
      withdrawToWallet: 'Wallet-Hilfe',
    },
    tools: {
      // title: 'Tools - CloverPool',
      // tools: 'Tools',
      // fppsPps: 'FPPS vs PPS Dynamischer Vergleich',
      // aboutFpps: 'Was ist gut an FPPS?',
      // charts: 'Diagramme haben eine hohe Aussagekraft.,
      // watchCharts: 'Diagramme beobachten',
      // miniCalc: 'Mining-Gewinn-Rechner',
      // miniCalcExpress:
      //   'Berechnen Sie den Mining-Gewinn in Kombination mit Preis, Schwierigkeit, Hashrate, Stromkosten, Stromverbrauch usw.',
      // miniMiniCalc: 'Mini-Mining-Gewinn-Rechner',
      // miniMiniCalcExpress: 'Mining-Gewinn schnell und einfach berechnen',
      // strat: 'Start',
      // btcTool: 'BTC-Tool',
      // btcToolIntroduce: "Batch-Prozess für die effiziente Konfiguration der Miner.",
      // download: 'Zum Download anklicken',
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Effizient und Transparent <br/>Leicht Nachverfolgbar',
      agentExpressBanner:
        'Effizient und Transparent <i style="margin-right:20px"></i> Leicht Nachverfolgbar',
      bit: 'Bit',
      document: 'Windows-Hilfedokument',
      poolApp: 'Voll ausgestattete CloverPool-App',
      poolRealTime: 'Pool- und Miner-Daten in Echtzeit anzeigen',
      poolOpeation: 'Miner direkt löschen/ändern',
      poolComming: 'Event-Warnfunktion bald verfügbar',
      poolDownlaod: 'Zum Download anklicken',
      title: 'Tools - CloverPool',
      tools: 'Tools',
      fppsPps: 'FPPS vs PPS Dynamischer Vergleich',
      aboutFpps: 'Was ist gut an FPPS?',
      charts: 'Diagramme haben eine hohe Aussagekraft.',
      watchCharts: 'Diagramme beobachten',
      miniCalc: 'Mining-Gewinn-Rechner',
      miniCalcExpress:
        'Berechnen Sie den Mining-Gewinn in Kombination mit dem Preis, der Schwierigkeit, der Hashrate, den Stromkosten, dem Stromverbrauch usw.',
      miniMiniCalc: 'Mini-Mining-Gewinn-Rechner',
      miniMiniCalcExpress: 'Mining-Gewinn schnell und einfach berechnen',
      start: 'Start',
      btcTool: 'BTC-Tool',
      btcToolIntroduce:
        'Batch-Prozess für die effiziente Konfiguration von Minern.',
      ipToolsTitle: 'Statische IP-Tools zur Konfiguration von Minern',
      ipToolsDes:
        'Alle Antminer unterstützen, belegte IP konfigurieren, Direktverbindung zum Pool konfigurieren',
    },
    miners: {
      createGroup: 'Neue Gruppe erstellen',
      default: 'STANDARD',
      addGroup: 'Neue Gruppe erstellen',
      deleteGroupTitle: 'Gruppe löschen',
      editGroupTitle: 'Gruppe bearbeiten',
      addGroupInfo:
        'Hinweis: Die ausgewählten Miner werden automatisch der neuen Gruppe hinzugefügt.',
      groupNameLabel: 'Gruppenname',
      addGroupEmpty: 'Der Gruppenname darf nicht leer sein.',
      groupRejected: 'Echtzeit-Ablehnungsrate',
      groupNameLimit:
        'Der Gruppenname muss zwischen 3 und 20 Zeichen lang sein',
      all: 'ALLE',
      inactive: 'Inaktiv',
      active: 'Aktiv',
      dead: 'Tot',
      moveTo: 'Verschieben nach',
      remove: 'Entfernen',

      allTooltip:
        'Enthält: Aktive Miner ({active}) + Inaktive Miner ({inactive})',
      inactiveTooltip:
        'Die Anzahl der Miner, die die Berechnungsergebnisse (Anteil) länger als 10 Minuten nicht übermittelt haben.',
      activeTooltip:
        'Die Anzahl der Miner, die normalerweise die Berechnungsergebnisse übermitteln (Anteil)',
      deadTooltip:
        'Anzahl der Miner, die das Berechnungsergebnis (Anteil) länger als 24 Stunden nicht übermittelt haben',

      home: 'Startseite',
      miners: 'Miner',

      workerName: 'Arbeitername',
      Hashrate: 'Echtzeit',
      Rejected: 'Ablehnungsrate',
      dailyHashrate: 'Täglich',
      firstShare: 'Erste Verbindung',
      LastShare: 'Letzte Anteil',
      groupName: 'Gruppenname',

      delMillTitle: 'Möchten Sie diese Miner-Informationen wirklich löschen?',
      delMillContext:
        'Dieser Vorgang löscht alle von Ihnen ausgewählten Miner, bitte gehen Sie vorsichtig vor.',

      delGroupTitle:
        'Sind Sie sicher, dass Sie die Gruppe {name} löschen möchten?',
      delGroupContext:
        'Die Miner in der Gruppe werden alle zum Standard wechseln',
      delMillFailed: 'Fehler beim Löschen',

      moveTitle: 'Miner verschieben',
      notChecked: 'Miner auswählen und Batch-Vorgang ausführen.',
      moveContext:
        'Dadurch werden alle ausgewählten Miner in die Gruppe {groupName} verschoben.',
      moveFaild: 'Kann nicht in die aktuelle Gruppe verschieben',

      searchPlaceholder: 'Geben Sie den Arbeiternamen ein',
      reportTime: 'Generierungszeit',
      refresh: 'Aktualisieren',
      editGroupName: 'Gruppennamen bearbeiten',
      workListEmpty: 'Kein Miner in dieser Gruppe erkannt',
      exist: 'Der Gruppenname wurde vergeben.',

      goNewVersion: 'Zur neuen Version zurückkehren',
      goOldVersion: 'Zur alten Version zurückkehren',
      dataExport: `Hashrate-Daten-Output der Miner der Gruppe "{name}"`,
      exportTip:
        'Bitte wählen Sie das Zeitintervall der zu exportierenden Daten. Die Daten können nur innerhalb von maximal 30 Tagen exportiert werden. Das exportierte Dateiformat ist CSV.',
      exportTip2: `Die Berechnungszeit der aktuellen Hashrate umfasst den Zeitraum von 00:00 bis 23:59 Uhr, und die Berechnungszeit der heutigen Hashrate umfasst den Zeitraum von 00:00 Uhr bis zur Exportzeit.`,
    },
    minerInfo: {
      title: 'Miners - CloverPool',
      miners: 'Miner',
      hashrate: 'Einzel-Hashrate',
      runningCondition: 'Betriebsbedingung',
      min: 'Min.',
      hour: '{n} Std.',
      hashrateAxis: 'Hashrate',
      rejected: 'Abgelehnt',
      status: 'Status',
      lastShare: 'Letzte Anteil',
      lastShareIP: 'Letzte Anteil-IP',
      hashrateChart: 'Hashrate-Diagramm',
      realTime: 'Echtzeit',
      trans: {
        seconds: 'Sek.',
        mins: ' Min.',
        hours: ' Std.',
        ago: ' zuvor',
        ACTIVE: 'Aktiv',
        INACTIVE: 'Inaktiv',
        DEAD: 'Tot',
      },
    },

    earningHistory: {
      title: 'Einnahmen-Verlauf - CloverPool',
      earn: 'Einnahmen-Verlauf',
      Unpaid: 'Unbezahlt',
      EarningsToday: 'Geschätzter Mining',
      EarningsYesterday: 'Gestrige Einnahmen',
      Time: 'Datum',
      NetworkDifficulty: 'Schwierigkeit',
      Earnings: 'Einnahmen',
      Payment: 'Zahlungszeit',
      payWithOTCAmount: 'Zahlen mit {otcType} : {amount}',
      Mode: 'Modus',
      Address: 'Die Anschrift',
      PaidAmount: 'Verdienste',
      yesterday:
        'Bezieht sich auf die Einnahmen von 0:00 bis 24:00 Uhr am Tag vor der UTC-Zeit.',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          'Wenn Sie die Adresse nicht festgelegt haben, werden Ihre Einnahmen im Guthaben gespeichert',
        PENDING_CHANGED_ADDRESS:
          'Die Zahlung erfolgt in 48 Stunden, wenn die Adresse geändert wird. Achtung: Ihr Konto (E-Mail/Telefonnummer) ist vermutlich gefährdet, wenn die Adresse nicht von Ihnen geändert wurde. Bitte registrieren Sie sofort ein neues Konto mit anderer E-Mail oder Telefonnummer und stellen Sie Ihre Miner auf das neue Konto um.',
        PENDING_NOT_ENOUGH:
          'Der Betrag ist geringer als die von Ihnen festgelegte Mindestzahlung (Standard ist: limit ), es wird Ihrem Guthaben gutgeschrieben.',
        PENDING_POOL_SETTLE_FAULT:
          'Aufgrund des Mining-Pools wird dieser Teil des Betrags irrtümlich ausgegeben und in Zukunft nicht mehr eingezahlt.',
      },
      depositeAmount: 'Gespeichert',
      'diff-chg': 'Schw. we.: ',
      'equals-pps': 'Entspricht PPS',
      Chg: 'We.',
      status: 'Zahlungsstatus',
      paid: 'Bezahlt',
      waitingPay: 'Warten',
      reason: 'Warum',
      fppsMoreEarn: '{mode} Extra:',
      fppsContain:
        'Im Vergleich zu PPS stiegen die Einnahmen des {mode}-Modells um etwa {pps96}',
      noEarningsInformation: 'Keine Einnahmeinformationen',
      earningDetail: 'Verdienstdetails',
      extraProfits: 'Extra Gewinne',
      autoSwap: 'Auto-Tausch',
      original: 'Ursprüngliche Währung',
      Equivalent: 'BTC-Äquivalent',
      Swapped: 'Getauscht',
      swapping: 'Tauschen',
      otcEarningTip:
        'Die Abrechnung der Zielwährungseinnahmen des Smart-Pool-Modus durch Auto-Tausch basiert auf dem realen Wechselkurs des Auto-Tauschs in Matrixport am Abrechnungstag.',
      originEarningTip:
        'Die Abrechnung der Zielwährungseinnahmen des Smart-Pool-Modus nach ursprünglicher Währung wird gemäß dem Wechselkurs des Auto-Tauschs in Matrixport am Abrechnungstag um 8:00 Uhr berechnet.',
      exportEarningsData: 'Einnahmen-Daten exportieren',
      exportTip:
        'Es kann bis zu 180 Tage an Daten unterstützen und das exportierte Dateiformat ist XLS.',
      originStatusTip:
        'Die Einnahmen werden per Auto-Tausch in die Zielwährung umgewechselt. Weitere Details finden Sie auf der Einnahmen-Seite des Smart-Pool-Modus. ',
      swappingTip:
        'Das laufende Einkommen wird gewechselt oder der Mindestwechselbetrag wird aufgrund des geringen Einkommens nicht erreicht',
      swappedTip:
        'Die Einnahmen werden per Auto-Tausch in die Zielwährung umgewechselt. Weitere Details finden Sie auf der Einnahmen-Seite des Smart-Pool-Modus',
      // upgrade TODO 收益改版新增
      settlementHashrate: 'Siedlungs-Hashrate',
      addressRatio: 'Prozentsatz',
      addressEarnings: 'Adressgewinn',
      paymentTime: 'Zahlungszeit',
      EarningsTodayTip:
        'Dies bezieht sich auf die geschätzten PPS-Einnahmen, die von 0:00 (UTC) bis zu diesem Zeitpunkt abgebaut wurden, und dieser Betrag kann von Ihren tatsächlichen Einnahmen abweichen.',
      TimeTip:
        'Das Datum ist in UTC-Zeit angegeben. Der 1. Januar 2020 bezieht sich beispielsweise auf 00:00:00 am 1. Januar 2020-00:00:00 am 2. Januar 2020 in UTC-Zeit.',
      EarningsTip:
        'Die Höhe der Einnahmen wird vom CloverPool Settlement Center" basierend auf mehreren Faktoren berechnet: der Methode der Einnahmenverteilung, der Netzwerkschwierigkeit, der Abrechnungs-Hashrate, der Basisprämie und der Tx-Gebührenprämie von Block sowie den Provisionssätzen usw. Es kann zu Abweichungen des theoretischen Verdienstes kommen, den Sie im Verdienstrechner berechnet haben.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Provisionse',
      inviteWorkerAccount: 'Unterkonto für eingeladene Personen',
      inviteRebateRatio: 'Provisionsverhältnis',
      inviteRebateAmount: 'Provisionsbetrag',
      rebateExportTip:
        'Es kann bis zu 60 Tage an Daten unterstützen und das exportierte Dateiformat ist XLS.',
    },

    //统计
    poolStats: {
      title: 'Pool-Statistik - CloverPool',
      poolStats: 'Pool-Statistik',
      hashrate: 'Hashrate',
      miners: 'Miner',
      coinType: 'Coin-Typ',
      found: 'Gefunden',
      hashrateChart: 'Hashrate-Diagramm',
      blocksRelayed: 'Blöcke übertragen',
      height: 'Höhe',
      time: 'Zeit',
      blocks: 'Blöcke',
      blockHash: 'Block-Hash',
      ddaScore: 'DAA Score',
      reward: 'Belohnung',
      day: 'Tage',
      region: 'Region',
      lucky: 'Lucky',
      btc: 'BTC',
      nmc: 'NMC',
      zec: 'ZEC',
      bcc: 'Bitcoin Cash',
      bch: 'Bitcoin Cash',
      eth: 'Ethereum',
      dcr: 'Decred',
      etc: 'Ethereum Classic',
      sbtc: 'Super Bitcoin',
      ubtc: 'United Bitcoin',
      grin: 'Grin',
    },

    // 守护者
    guardian: {
      expiredDeadline: ' (:Tag automatisch gelöscht)',
      guardianRemark:
        'Sie können alle (oder einen Teil) Ihrer Unterkonteninformationen (einschließlich Hashrate, Miner) mit einem anderen Benutzer teilen, der diese nur ansehen, aber keine Einstellungen ändern kann.',
      createGuardian: 'Gemeinsamen Benutzer hinzufügen',
      remark: 'Bemerkung:',
      user: 'Benutzer:',
      manage: 'Verwalten',
      status: 'Status:',
      theGuardian: 'Geteiltes Konto:',
      creationDate: 'Erstellungsdatum:',
      bounded: 'Begrenzt',
      datePrevious: 'Datum der vorherigen Verwendung:',
      delete: 'Löschen',
      deleteGuardianLink: 'Den Link zum geteilten Konto löschen',
      createGuardianInvitation: 'Link des geteilten Kontos erstellen',
      guardianAccountManagement: 'Geteiltes-Konto-Verwaltung',
      modifyRemark: 'Bemerkung ändern',
      validateRemarkEmpty: 'Bemerkung darf nicht leer sein',
      enterRemark: 'Bemerkung hinzufügen',
      noteInformation:
        'Hinweis: Diese Bemerkung wird verwendet, um mehrere geteilte Konten zu unterscheiden. Wir empfehlen, die Kontaktinformationen zu verwenden.',
      confirm: 'Bestätigen',
      create: 'Erstellen',
      cancel: 'Abbrechen',
      guardianSuccessfullyCreated:
        'Der Einladungslink für das geteilte Konto wurde erfolgreich erstellt',
      copyLink: 'Link kopieren',
      authorized:
        'Nach dem Löschen verlieren andere die Verwaltungsberechtigung für Ihr Unterkonto.',
      noteSendGuardian:
        'Tipp: Bitte senden Sie diesen Link an zuverlässige Personen, die Bedarf an Miner-Verwaltung haben. Dieser Link kann einmalig verwendet werden. Falls der Link abgelaufen ist, dann erstellen Sie ihn bitte neu. ',
      close: 'Schließen',
      subaccount: 'Unterkonto',
      coinType: 'Coin-Typ',
      region: 'Region',
      guardianStatus: 'Teilen',
      guardianSelectExpress:
        'Durch den Schalter im Freigabestatus können Sie auswählen, ob ein fremdes Konto Ihr Unterkonto verwalten darf oder nicht. (Die Standardeinstellung ist, dass der Betreuer alle Ihre Unterkonten besuchen kann.)',
      invitTitle: 'Mein Konto mit anderen teilen',
      acceptTitle: 'Vor mir akzeptiertes Konto eines anderen',
      losePower:
        'Wenn Sie es löschen, verlieren Sie die Berechtigung zur Verwaltung des Unterkontos.',
      show: 'Anzeigen',
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation:
        'Bindung des Einladungslinks für das geteilte Konto',
      advance: 'Erweitert',
      guardianAccountManagement: 'Bindung des Links des geteilte Kontos:',
      confirmGuardianBind: 'Kontoverwaltung des geteilten Kontos',
      remark: 'Bemerkung:',
      enterRemark: 'Bemerkung hinzufügen',
      suggestName:
        'Wir empfehlen, den Namen zu verwenden, um die Verwaltung bequemer zu machen. Wenn Sie das Feld leer lassen, bedeutet dies, dass Sie standardmäßig eine Telefonnummer oder E-Mail-Adresse verwenden.',
      guardian: 'Verknüpfen',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      manageView: ', um alle eigenen Unterkonten zu verwalten?',
      suggestUsingName:
        'Wir empfehlen, den Namen zu verwenden, um die Verwaltung bequemer zu machen. Wenn Sie das Feld leer lassen, bedeutet dies, dass Sie standardmäßig eine Telefonnummer oder E-Mail-Adresse verwenden.',
      sms: 'Telefon',
      email: 'E-Mail',
      code: '{mode}-Bestätigungscode',
      send: 'Senden',
    },
    account: {
      subAccountManage: 'Unterkontoverwaltung',
      currentAccount: 'Aktuelles Konto',
      locationRegion:
        'Ihr Standort befindet sich in <strong>{coin}</strong> <strong>{region}</strong> Region',
      locationEx:
        'Jedes Unterkonto entspricht einem festen Symbol und Bereich, die Währung und der Bereich können nach der Erstellung nicht mehr geändert werden. Wenn andere Anforderungen bestehen, können Sie ein neues Unterkonto erstellen.',
      general: 'Allgemeine Version',
      advance: 'Erweiterte Version',
      create: 'Erstellen',
      algorithm: ' Algorithmus',
      oneButtonSwitch: 'Ein-Klick-Wechseln',
      addOthersCoins: 'Andere Coins hinzufügen',
      supportCoins: 'Unterstützung',
      hiddenSubAccounts: 'Versteckte Unterkonten verwalten',
      hashAlert: 'Einwandfrei',
      viewHideSubAccount: 'Verstecktes Unterkonto anzeigen',
      addSubAccount: 'Unterkonto hinzufügen',
      recover: 'Wiederherstellen',
      latestAlert: {
        hashrate: 'Hashrate {symbol} {expect} {unit}',
        miners: 'Miner-Anzahl {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'Unterkonto',
      currency: 'Währung',
      region: 'Region',
      walletAddressAndMinimumPayment: 'Wallet-Adresse',
      walletAddress: 'Wallet-Adresse',
      shareData: 'Geteilte Konten',
      shareDataTip:
        'Sie können alle oder einen Teil Ihrer Unterkontoinformationen mit anderen teilen, die sie nur anzeigen, aber nicht ändern können; Sie können auch alle oder einen Teil der Unterkontoinformationen anderer Personen im schreibgeschützten Modus erhalten.',
      alertSettings: 'Alarm einstellen',
      mywatcher: 'Beobachter einstellen',
      apikey: 'API KEY',
      payWithBtc: 'Mit BTC bezahlen',
      //Wallet-Adresse & Mindestzahlung
      setAddress: 'Bitte geben Sie eine {coinType}-Adresse ein.',
      address: 'Adresse',
      createAddress: 'Adresse hinzufügen',
      modifyAddress: 'Ihre Wallet-Adresse ändern',
      modify: 'Modifizieren',
      minPay: 'Grenzwert',
      lastUpdateTime: 'Aktualisierungszeit der Adresse',
      updateBitconAddressTip:
        'Hinweis: Aus Sicherheitsgründen werden Ihre Einnahmen 48 Stunden lang als Guthaben gespeichert.',

      //Beobachter
      old: 'Alt',
      new: 'Neu',
      continue: 'Weiter',
      emptyAddress: 'Adresse darf nicht leer sein',
      addAddress: 'Festlegen',
      enterCode: 'Code eingeben',
      newBit: 'Ihre neue Adresse',
      way: 'Wir senden Ihnen einen Code, um diese Änderung zu bestätigen. Wie möchten Sie ihn erhalten?',
      sendCode:
        'Wir haben Ihnen einen Code gesendet, um diese Änderung zu bestätigen',
      sendVerifyCode:
        'Wir haben Ihnen einen Code zur Bestätigung dieser Änderung {contactType}',
      sms: 'SMS verschickt',
      mail: 'E-Mail verschickt',
      mailVia: 'Verifizierungscode an Mailbox senden',
      smsVia: 'Verifizierungscode an Smartphone senden',
      errorLater: 'Bitte versuchen Sie es später noch einmal ...',
      updateSuccess: 'Adressaktualisierung erfolgreich!',
      // 多地址收款
      multAddress: {
        tag: 'Mehrere Adressen',
        addMultAddress: 'Adresse hinzufügen',
        adjustProportion: 'Verhältnis anpassen',
        adjustMinPay: 'Grenzwert anpassen',
        address: 'Adresse',
        proportion: 'Verhältnis',
        remark: 'Hinweis',
        lastUpdateTime: 'Neueste Zeit',
        operation: 'Vorgang',
        emptyText: 'Sie haben keine Adresse hinzugefügt',
        emptyTextForMult:
          'Keine Daten, es können bis zu 20 Adressen hinzugefügt werden',
        cancel: 'Abbrechen',
        nextStep: 'Nächster Schritt',
        delete: 'Löschen',
        modify: 'Modifizieren',
        newAddressPlaceholder: 'Neue Adresse',
        addressMaxCountTip:
          'Derzeit können Sie bis zu {maxCount} Empfängeradressen in einem Unterkonto hinzufügen. Wenn Sie weitere Adressen benötigen, wenden Sie sich bitte an den Kundenservice unter support@connectbtc.com.',
        updateBitconAddressTip:
          'Aus Sicherheitsgründen wird die Zahlung innerhalb von 48 Stunden nach diesem Vorgang ausgesetzt und Ihre Forderungseinnahmen werden am ersten Abrechnungstag nach 48 Stunden ausgezahlt.',
        emptyRemark: 'Keine Hinweise hinzugefügt',
        noAddressForAdjust:
          'Sie haben keine Adresse hinzugefügt. Bitte fügen Sie zuerst einige Adressen hinzu, bevor Sie das Verhältnis oder den Grenzwert anpassen.',
        balanceMoveTip:
          'Die unbezahlte Kryptowährung des aktuellen Unterkontos wird auf "Neue Adresse 1" übertragen, bitte bestätigen Sie Ihren Vorgang.',
        confirmModalTitle: 'Sicherheitsüberprüfung',
        authentication: 'Authentifizierung',
        operationConfirm: 'Vorgang bestätigen',
        addAddressConfirmTip: 'Sie fügen {count} Adressen hinzu',
        modifyProportionConfirmTip:
          'Sie passen das Verhältnis jeder Empfangsadresse an',
        deleteAddressConfirmTip: 'Sie löschen die Adresse <br/>{address}',
        selectBalanceMoveAddress: 'Wählen Sie eine Erbschaftsadresse',
        selectBalanceMoveAddressTip:
          'Die unbezahlte Kryptowährung der zu löschenden Adresse beträgt {balance}{coinType}. Nach diesem Vorgang wird es innerhalb von 24 Stunden an die oben ausgewählte neue Adresse übertragen.',
        cannotDeleteAddress: 'Die Adresse kann nicht gelöscht werden',
        deleteAddressRule:
          'Nur Adressen mit einem Auszahlungsverhältnis von 0,00% können gelöscht werden.',
        // deleteAddressRuleDetail:
        //   '1. Das Verhältnis beträgt 0,00%;<br/>2. Die Smart-Mining-Adresse desselben Unterkontos ist nicht als diese Adresse festgelegt.',
        // usedBySmart:
        //   'Das Smart-Mining des aktuellen Unterkontos wählt die aktuelle Adresse aus, so dass diese Änderung gleichzeitig die Mining-Adresse des Geräts ändert.',
        operationSuccess: 'Funktioniert einwandfrei',
        validate: {
          emptyAddress: 'Bitte neue Adresse eintragen',
          emptyAddressForUpdate: 'Adresse darf nicht leer sein',
          emptyProportion: 'Bitte geben Sie das Verhältnis der Adresse ein',
          emptyProportioAfter: '',
          proportionInvalidate:
            'Der Anteil jeder Adresse muss größer oder gleich 0 % sein und die Summe aller Anteile muss gleich 100 % sein',
          maxCount:
            'Für jedes Unterkonto können bis zu 20 Adressen festgelegt werden',
          duplicateAddressPrefix:
            'Die gleiche Adresse kann nur einmal hinzugefügt werden, aber die Adresse',
          duplicateAddressSuffix: ' wird wiederholt hinzugefügt.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },
      //Mindestzahlung
      currentMinPay: 'Aktuelle Mindestzahlung:',
      minPayAllSubAccount: 'Mindestzahlung ist für alle BTC-Unterkonten gleich',
      todayUpdateMinPay:
        'Mindestzahlung von Mining-Einnahmen wurde ab heute geändert: {minPay}',

      //Mit Beobachter teilen
      watcher: 'Beobachter',
      availableWatchers: 'Verfügbare Beobachter',
      watcherExpress:
        '* Beobachter können Ihre Daten über die unten stehende URL einsehen, aber Ihre Kontoeinstellungen nicht ändern.',
      createWatcher: 'Neuen Beobachter erstellen',
      modifyWatcher: 'Ändern',
      authoritySettings: 'Berechtigungseinstellungen',
      setWatcherName: 'Legen Sie einen Namen für diesen Beobachter fest',
      watcherName: 'Beobachtername',
      watcherNameTip: '2-20 Zeichen',
      notEmptyWatcherNote: 'Beobachtername darf nicht leer sein',
      tips: 'Tipps:',
      tip_dashboard:
        '1. Dashboard enthält keine Verdienstinformationen wie "Unbezahlt" und "Bezahlt";',
      tip_earning:
        '2. Einnahmen umfassen Informationen wie "Unbezahlt" und "Bezahlt" jeder Adresse.',
      moreSettings: 'Mehr Einstellungen',
      guardianRemark:
        'Sie können alle (oder einen Teil) Ihrer Unterkonteninformationen (einschließlich Hashrate, Miner) an andere Benutzer teilen, die die Einstellungen sehen, aber nicht ändern können.',
      guardian: 'Geteilte Konten',
      shareAccountsSetting: 'Alarm einstellen',
      accessKey: 'Zugriffsschlüssel',
      generateAK: 'Zugriffsschlüssel generieren',
      puid: 'PUID',
      apiDoc: 'API-Dokument',
      confirmDeleteWatcher: 'Bestätigen, um den Beobachter zu löschen?',
      watcherAuths_Dashboard: 'Dashboard',
      watcherAuths_Dashboard_Tip: '(ohne Einnahmen)',
      watcherAuths_Miners: 'Miner',
      watcherAuths_Earnings: 'Einnahmen',
      watcherAuths_GetCoin: 'Geschenk',

      // Warneinstellungen
      alertItem: 'Warn-Gegenstand',
      alertContacts: 'Warn-Kontakte',
      hashrateAlert: 'Hashrate-Warnung',
      minerAlert: 'Miner-Warnung',
      alertFrequency: 'Warnhäufigkeit',
      alertFrequencyTip:
        'Hiermit wird mitgeteilt, dass aufgrund der Richtlinien die Häufigkeit von SMS-Benachrichtigungen in einigen Ländern und Regionen alle 24 Stunden festgelegt wird.',
      warnMeWhenHashrate: 'Hashrate Warnzeile ≤',
      warnMeWhenActiveMiner: 'Mich warnen, wenn aktiver Miner ≤',
      dontWarnMeTwice: 'Warnen Sie mich nicht zweimal in ',
      hours: 'Stunden',
      apply: 'Anwenden',
      operateFailed: 'Vorgang fehlgeschlagen!',
      contactsList: 'Warn-Kontakte',
      cancel: 'Abbrechen',
      ok: 'OK',
      save: 'Speichern',
      reset: 'Zurücksetzen',
      newContact: 'Neuen Kontakt hinzufügen',
      editContact: 'Kontakt ändern',
      deleteContact: 'Kontakt löschen',
      confirmDeleteContact: 'Bestätigen, um den Warn-Kontakt zu löschen?',
      name: 'Name',
      phone: 'Telefon',
      email: 'E-Mail',
      validateAlert: {
        nameEmpty: 'Name darf nicht leer sein',
        phoneRegionFormat: 'Ungültiger Regionalcode',
        phoneFormat: 'Ungültige Telefonnummer',
        emailFormat: 'Ungültige E-Mail',
        contactEmpty:
          'Das E-Mail-Feld ist erforderlich, wenn keine Telefonnummer festgelegt wurde.',
      },
      max_email_contacts: 'Unterstützt bis zu maximal 10 E-Mail-Kontakte',
      max_phone_contacts: 'Unterstützt bis zu 5 Telefonkontakte',
      enabled_alert_need_hashrate:
        'Keine Hashpower erkannt, Warnung kann nicht aktiviert werden.',
      alertItemUpdateSuccess: 'Warnung für Gegenstand-Update erfolgreich',

      done: 'Fertig',

      Okay: 'Okay',

      invalidAddress: 'Ungültige Adresse',
      emptyCode: 'Darf nicht leer sein',
      wrongCode: 'Falscher Code',
      emailVia: 'E-Mail an mich mit',
      textVia: 'SMS an mich mit',
      help: 'Hilfe',

      contact: 'Kontakt:',
      close: 'Schließen',
      receiveAddress: 'Adresse:',

      updateAddress: 'Aktualisierungszeit der Adresse',
      // Mit BTC bezahlen
      payWithBtcTip:
        '* Nach dem Einschalten der Währungsumrechnung wird Ihr Mining-Gewinn täglich zu einem festen Zeitpunkt in Bitcoin umgewandelt und an die folgende individuelle BTC-Adresse ausgezahlt. Die Auszahlung erfolgt normalerweise um 4:00 Uhr UTC. In Ausnahmefällen erhalten Sie eine Benachrichtigung.',
      convertedCurrency: 'Umgerechnete Währung: ',
      setBtcAddressTip: 'Bitte geben Sie eine BTC-Adresse ein',
      btcAddress: 'BTC-Adresse: ',
      conversionRate: 'Referenz-Umrechnungskurs',
      convert: 'Umrechnen',
      getValue: 'Sie erhalten ',
      conversionTip:
        '* Der Umrechnungskurs dient nur als Referenz, vorbehaltlich des tatsächlichen Umrechnungs-Ergebnisses.',
      lastUpdate: 'Zuletzt aktualisiert: ',
      on: 'Ein',
      off: 'Aus',
      turnOnPaywith:
        'Sind Sie sicher, dass Sie das Bezahlen mit BTC aktivieren möchten?',
      turnOffPaywith:
        'Sind Sie sicher, dass Sie das Bezahlen mit BTC deaktivieren möchten?',
      payOn: 'Aktivieren',
      payOff: 'Deaktivieren',
      resend: 'Erneut senden',
      send: 'Code senden',
      resendTip:
        'Wenn Sie den Code nicht erhalten, warten Sie bitte eine Weile oder versuchen Sie es noch einmal',

      // 一键切换 One Switch
      oneSwitch: {
        attention: 'Achtung',
        optionCurrentMode: 'Bitte wählen Sie den Modus',
        switch: 'Sie sind dabei, Ihre Hashrate auf {destMode} umzustellen',
        switchAnotherMining: 'Sie wechseln zum {destMode}-Mining.',
        hashsHaveSwitched:
          'Alle Mining-Hashes wurden auf den {destMode}-Pool umgestellt.',
        ethSwitchWarning:
          'Warnung: Wenn Ihr Mining-Gerät das Umschalten von DAG nicht unterstützt, werden Sie keine Einnahmen generieren. Sie müssen den Miner manuell neu starten, um zum Normalzustand zurückzukehren. (Es ist bekannt, dass der AntMiner E3 dies nicht unterstützt. Der Wechsel von ETC zu ETH erfordert einen manuellen Neustart).',
        ethSwitchWarningLinkTxt: 'Details.',
        checkSubAccount:
          'Bitte überprüfen Sie Ihr Unterkonto {subAccountName}-{subAccountRegionNode}',
        enter: 'Eingeben',
      },
      // 机枪收款地址
      smartAddress: {
        // autoSwapSettlement: 'Auto-Tausch Abrechnung',
        original: 'Ursprüngliche Währungsabrechnung',
        autoTip:
          'Vom Smart-Pool generierte Währungen werden täglich in der Zielwährung abgerechnet.',
        originalTip:
          'Die Smart-Pool-Abrechnung wird in BTC, BCH und BSV abgerechnet, die von Smart-Pool unterstützt werden.',
        originalCurrency: 'Ursprüngliche Währung',
        targetCurrency: 'Zielwährung',
        earningsInSmartPool: 'Einnahmen im Smart-Pool',
        activated: 'Aktiviert',
        unactivated: 'Nicht aktiviert',
        modifyTip:
          'Die Zielwährung der Smart-Pool Auto-Tausch-Abrechnung ist mit der Wallet-Adresse derselben Währung unter dem identischen Unterkonto verknüpft und konsistent.',
        autoHistory: 'Auto-Tausch von bisherigen Einnahmen in BTC?',
        notice1:
          'Aus Sicherheitsgründen werden Ihre Einnahmen 48 Stunden lang als Guthaben gespeichert.',
        notice2:
          'Die Einnahmen von Smart-Pool werden nach der Aktivierung des Auto-Tauschs automatisch in die Zielwährung getauscht. Wenn Sie auf OK klicken, bedeutet dies gleichzeitig, dass Sie der {fundLink} zustimmen.',
        notice3:
          'Die Adresse im Smart-Modus ist mit der einzelnen Coin-Adresse unter demselben Unterkonto verknüpft und konsistent (wenn mehrere Adressen festgelegt sind, stimmt sie mit der ersten Adresse überein)',
        originalNotice1:
          'Die Adresse im Smart-Modus ist mit der einzelnen Coin-Adresse unter demselben Unterkonto verknüpft und konsistent (wenn mehrere Adressen festgelegt sind, stimmt sie mit der ersten Adresse überein)',
        currentPayoutMode: 'Aktueller Auszahlungsmodus: {mode}',
        fundLink: `“Lizenzvereinbarung des  Währungs-Auto-Tauschs von CloverPool”`,
        originBtn: 'Ursprüngliche Währungsabrechnung aktivieren',
        otcBtn: 'Aktivieren des Auto-Tauschs für die Abrechnung',
        otcSuccess:
          'Auto-Tausch-Abrechnung des Smart-Pool-Modus ist aktiviert und wir werden die Zahlung mit BTC vornehmen.',
        otcModalNotice2: `Bei Aktivierung des Auto-Tauschs von bisherigen Einnahmen werden die bisherigen Einnahmen auf dem Konto getauscht. Ohne die Aktivierung werden nur die letzten Einnahmen getauscht.`,
        originSuccess:
          'Die ursprüngliche Währungsabrechnung des Smart-Pool-Modus ist aktiviert und wir werden die Zahlung mit der nach Hashrate abgebauten ursprünglichen Währung vornehmen.',
        // 2021-06-04新增
        mode_otc: 'Auto-Tausch Abrechnung',
        mode_origin: 'Ursprüngliche Währungsabrechnung',
        settlementMode: 'Abrechnungs-Modus',
        settlementModeDescription: 'Abrechnungsbeschreibung',
        originDescription:
          'Ursprüngliche Währungsabrechnung: Abrechnung in der aktuellen Mining-Währung, der Währungsbereich ist BTC, BCH und BSV.',
        autoSwapDescription:
          'Auto-Tausch-Abrechnung: Führen Sie zunächst das dynamische Mining von BTC/BCH/BSV gemäß dem Algorithmus durch und konvertieren Sie die Währungen dann zur Abrechnung in BTC.',
        settlementSwitch: 'Abrechnungswechsel',
        originalSettlement: 'Ursprüngliche Währungsabrechnung',
        autoSwapSettlement: 'Auto-Tausch Abrechnung',
        currency: 'Währung',
        address: 'Adresse',
        addressRules: 'Adressregeln',
        switchAddress: 'Adresse wechseln',
        noAddressTip:
          'Für {coin} desselben Unterkontos wurde keine Adresse hinzugefügt',
        noAddressSelected: 'Keine Adresse ausgewählt',
        selectFrom:
          'Aus der Liste der BTC-Adressen desselben Unterkontos auswählen',
        settingTip:
          'Tipp: Wenn Sie die "Adresse des Smart-Pools" von der "Adresse der Einzelwährung des gleichen Unterkontos" unterscheiden möchten, fügen Sie bitte eine neue Adresse in der Einzelwährungs-Adressverwaltung hinzu und stellen Sie das Verhältnis auf 0% ein. Kehren Sie anschließend hierher zurück, um zur neuen Adresse zu wechseln.',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Effizient und Transparent Leicht Nachverfolgbar',
      bit: 'Bit',
      bit64: 'Windows 7 oder höher, 64-Bit',
      bit32: 'Windows XP, 32-Bit',
      document: 'Windows-Hilfedokument',
      accessLinuxVersion: 'Zugriff für Linux-Version',
      cuttingEdgeTechnology: 'Neuste Technologie',
      latestArchitecture: 'Neueste & Ursprüngliche Architektur',
      openSourceCode: 'Open-Source-Code',
      highTestPressure: 'Ultra-hoher Stresstest',
      lowOrphanedRate: 'Ultra-niedrige verwaiste Blockrate',
      transparentAgent: 'Transparenter Agent',
      standardsServices: 'Neue Standards für Mining-Dienste',
      customerHotline: 'Kunden-Hotline',
      highHashrateConsultation: 'Beratung für hohe Hashrate',
      professionalCustomerService: 'Professioneller Kundenservice',
      bestVipServer: 'VIP-Server mit ultrahoher Spezifikation',
      oneToOne: 'Eins-zu-Eins-Fallanalyse',
      appAvailable:
        'CloverPool App unterstützt die einfache Verwaltung von Minern',
      earningsTimelyEveryday: 'Jeden Tag pünktlich Einnahmen',
      transnationalTeamOperation: 'Internationales Team',
      currencies: 'Währungen',
      dailyEarnings: 'Tägliche Einnahmen',
      hashrate: 'Hashrate',
      hashrateChart: 'Hashrate-Diagramm',
      explorer: ' Explorer',
      prices: 'Preis',
      earningsModels: 'Einnahme-Modelle',
      minPayment: 'Zahlungsgrenze',
      help: 'Hilfe',
      notePaytime: 'Täglich 0:00-8:00 (UTC) Auszahlung der gestrigen Einnahmen',
      yuanKwhElectric: 'EUR/kW·Std. (Strompreis)',
      todayEarnings: 'Heutige Einnahmen:',
      piece: 'Stück',
      signIn: 'Anmelden',
      signUp: 'Registrieren',
      power: 'Power',
      hashrateProfitEst: 'Geschätzter Hashrate-Gewinn',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: 'Geschätzter Mining-Gewinn',
      fee: 'Gebühr',
      desc: `Der weltweit führende, umfassende Service-Mining-Pool für mehrere Währungen`,
      tutorial: 'Tutorial',
      diff: 'Schwierigkeit',
      nextDifficultyEstimated: 'Geschätzte nächste Schwierigkeit',
      dateToNextDifficulty: 'Datum bis zur nächsten Schwierigkeit',
      Algorithm: 'Algorithmus',
      backup: 'Backup',
      whyMe: {
        title: 'Warum CloverPool wählen?',
        leadingTechnology: 'Führende Technologie',
        convenientManagement: 'Bequemes Verwaltung',
        revenueAssurance: 'Gesicherte Umsätze',
        professionalServices: 'Professionelle Dienstleistungen',
        desc1: `Open-Source-Code, kostenlose Management-Software von Minern und Mining-Farmen, offizielle angepasste Übertaktungs-Firmware steigert den Umsatz erheblich, Blockchain-Daten und technischer Dienstleister.`,
        desc2:
          'Präzise App- und Webseiten-Bedienung, einfache Kontoregistrierung mit Telefonnummer oder E-Mail, unabhängige Warnfunktion, Umsatzadresse, Miner-Gruppierung und Beobachter-Link zum Teilen von Daten.',
        desc3: `Echte und transparente Mining-Daten, fortschrittliches FPPS-Umsatzmodell, leistungsstarkes Abrechnungszentrum und pünktliche Zahlung.`,
        desc4: `CloverPool verfügt über ein professionelles Kundenserviceteam, den höchsten Standard an maßgeschneiderten VIP-Diensten und die besten Forscher der Branche analysieren die Fälle von Person zu Person.`,
      },
      app: {
        title: ' App',
        description: `Der weltweit führende integrierte Multi-Währungs-Mining-Pool`,
        property1: `Unterstützt das Mining in mehreren Währungen, die Verwaltung mehrerer Unterkonten, 
  und eine einfache Verwaltung beim Teilen von Beobachter-Links`,
        property2: `Echtzeitanzeige umfassender Währungsdaten,
     schnelleres Erfassen von Mining-Trends`,
        property3:
          'Neue interaktive Oberfläche für eine reibungslosere Benutzererfahrung',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: `Der Abrechnungsmodus der ETH ist der FPPS/PPS+ Dual-Modus, und der aktuelle Modus ist der FPPS-Modus; wir werden ihn entsprechend umfassender Faktoren wie Transaktionskosten in der Chain, dem Marktanteil von CloverPool, den Marktbedingungen und dem Einkommen der Nutzer anpassen.`,
            'PPS+': `Der Abrechnungsmodus der ETH ist der FPPS/PPS+ Dual-Modus, und der aktuelle Modus ist der PPS+-Modus; wir werden ihn entsprechend umfassender Faktoren wie Transaktionskosten in der Chain, dem Marktanteil von CloverPool, den Marktbedingungen und dem Einkommen der Nutzer anpassen.`,
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'Die Ausrüstung, die nur ETC mint, verbindet sich mit dieser Mining-Adresse',
      zilMinerAddressTips:
        'ETC- und ZIL-Dual-Mining-Ausrüstung ist mit dieser Mining-Adresse verbunden',
    },
    advanceAccount: {
      title: 'Erweiterte Kontoverwaltung - CloverPool',
      all: 'ALLE',
      btc: 'BTC',
      bch: 'BCH',
      sbtc: 'SBTC',
      ubtc: 'UBTC',
      dcr: 'DCR',
      ltc: 'LTC',
      grin: 'Grin',
      beam: 'BEAM',
      unit: 'Einheit',
      subAccount: 'Unterkonto',
      coin: 'Coin',
      hashrate: 'Hashrate',
      guard: 'Hinweis',
      allMiners: 'Alle Miner',
      activeMiners: 'Aktiv',
      inactiveMiners: 'Inaktiv',
      deadMiners: 'Tot',
      region: 'Region',
      more: 'Mehr',
      setting: 'Einstellung',
      totalHashrate: 'Gesamte Hashrate',
      search: 'Suchen',
      status: 'Status',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: 'Ab sofort können Sie Namecoin (NMC), Elastos (ELA) kostenlos erhalten, wenn Sie Bitcoin oder Bitcoin Cash im Mining-Pool von CloverPool minen.',
        bch: 'Ab sofort können Sie Namecoin (NMC), Elastos (ELA) kostenlos erhalten, wenn Sie Bitcoin oder Bitcoin Cash im Mining-Pool von CloverPool minen.',
        ltc: 'Ab sofort können Sie Dogecoin（PPLNS） kostenlos erhalten, wenn Sie Litecoin im Mining-Pool von CloverPool minen.',
        bcha: `<p>Die Bitcoin Cash (BCH)-Hardfork hat das Bitcoin-Cash-Netzwerk in zwei neue Blockchains aufgeteilt, BCHA ist eine neuer Coin. Weitere Informationen finden Sie unter <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a></p>
          <p><b>Auszahlungszeitraum:</b><br/>07/12/2020 00:00 Uhr bis 07/01/2021 00:00 Uhr (UTC)</p>`,
      },
      beta: 'In der geschlossenen Beta',
      whatsThis: 'Was ist das?',
      address: 'Adresse:',
      newAddress: 'Neue Adresse',
      addressComplete:
        'Um Ihre Einnahmen zu schützen, geben Sie bitte so schnell wie möglich Ihre Adresse für den Erhalt des kostenlosen Tokens an.',
      bchaAddressComplete:
        'Nach dem 7. Januar 2021 werden Benutzer, die die Adresse nicht eingeben, vom BCHA gelöscht und es werden keine weiteren Auszahlungen erfolgen. Um Ihre Einnahmen zu schützen, geben Sie bitte so schnell wie möglich Ihre Adresse für den Erhalt des kostenlosen Tokens an.',
    },
    watcher: {
      expired: 'Beobachterberechtigung abgelaufen',
      incorrect: 'Beobachter-Link-Fehler',
    },
    activity: {
      pointsToday: 'Punkte heute',
      pointsSum: 'Summe',
      ranking: 'Rangliste',
      Earnings: 'Einnahmen',
    },
    minerRanking: {
      title: 'Mining-Gewinnschätzung',
      electric: 'Gebühr/Tag',
      dailyEarning: 'Tagesgewinn',
      priceShutdown: 'Shutdown-Preis',
      ALL: 'ALLE',
      CNY: 'CNY',
      USD: 'USD',
      desc: 'Gewinn absteigend',
      asc: 'Gewinn aufsteigend',
      electricPercent: 'Stromgebühren-Verhältnis',
      searchTip: 'Miner eingeben',
      updated: 'Aktualisiert um',
      coin: 'Coins',
      emptyShow: 'Keine berechtigten Miner',
      rank: 'Rang',
      miner: 'Miner',
      power: 'Power',
      hashrate: 'Hashrate',
      rev24h: 'Rev.24 Std.',
      energy: 'Stromgebühren',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
