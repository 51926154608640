import flatten from 'flat';
const commonMessage = {
  common: {
    locale: '日本語',
    header: {
      home: 'プールホーム',
      dashboard: 'ダッシュボード',
      getCoin: 'コインマイニング',
      help: 'ヘルプ',
      miners: 'マイニングマシン',
      poolStats: '統計',
      commissionEarnings: 'リベート収入',
      miningEarnings: '鉱業収入',
      service: 'カスタマーサービス',
      tools: 'ツール',
      setting: '設定',
      guardian: 'シェア',
      guardianPermission:
        'アカウントデータを閲覧する権利のみがあります,編集操作はできません。 ',
      watcher: '観察者',
      watcherPermission:
        '観察者にはアカウントデータを閲覧する権利のみがあります,編集操作はできません。 ',
      userCenter: 'ユーザーセンター',
      SignOut: '退出',
      accountSetting: 'アカウント設定',
      poolManagePanel: 'パネル',
      watcherMode: '観察者モード',
      scanToService: 'スキャンでヘルプ',
      bitdeer: 'Bitdeer',
      hashnest: 'ハッシュネスト',
      app: 'アプリ',
      explorer: 'ブラウザ',
      getBCHA: 'BCHA受取',
      candy: 'キャンディ',
      alertBar:
        '<b>在8月10日 22:00 - 24:00(UTC+8)</b>， CloverPoolプールはまもなく大規模なデータメンテナンスを実施します,それにより一部の機能は使用できなくなりますが,マイニングサービスは影響を受けません。<a href="https://help.cloverpool.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">詳細はこちらをご覧ください</a>',
      introTip:
        'ダッシュボード,マイニングマシン,収入,子アカウント設定などの機能は「プールダッシュボード」に統合されています。 ',
    },
    nav: {
      'nav-explorer': 'ブラウザ',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'プール',
      'nav-pool-home': 'プールホーム',
      'nav-pool-data': 'プールデータ',
      'nav-pool-candy': 'キャンディコミュニティ',
      'nav-pool-tools': 'ツールソフトウェア',
      'nav-pool-cs': '問い合わせ',
      'nav-wallet': 'ウォレット',
      'nav-help': 'ヘルプ',
      'nav-navigation': 'ナビゲーション',
      'nav-inscriptions': '記す',
      'nav-app': 'APP',
      'nav-collaborate': '協力',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'ツール',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: '残高',
      coinAddress: '{coinType} 受取アドレス',
      setAddress: '{coinType}受取アドレスを設定してください',
      multAddressEnabled: 'マルチアドレス設定済みです',
      menu: {
        hide: '隠す',
        address: '受取アドレス',
        minPay: '最小支払金额',
        payWithBtc: 'BTCで清算',
        earingsHistory: '収益',
        shareData: 'アカウント共有',
        watcher: 'ウォッチャー設定',
        apikey: 'API KEY',
        alertSettings: 'アラート設定',
        onebuttonSwitch: '一括切替',
        userCenter: 'ユーザーセンター',
        signOut: 'サインアウト',
        delete: '削除',
      },
      confirm: '確認',
      cacel: '取消',
      deleteConfirm: '削除確定',
      cannotDelete: 'この子アカウントは削除できません',
      cannotDeleteView: '現在閲覧中の子アカウントを削除できません',
      deleteCondition:
        '子アカウントを削除できません<span class="subAccountName">{{accountMessage}}</span>,以下の２つの条件を同時に満たした子アカウントのみ削除できます:',
      cannotDeleteReason:
        '1,その子アカウントの過去１８０日間のハッシュレートが０の場合；<br/>2,サブアカウント（ギフトコインを含む）の未払いの暗号通貨は0です；<br/>3,サブアカウントは、いかなる種類の特別なサブアカウントにも属していません。',

      deleteName: '子アカウントを削除しています :',
      deleteInfo:
        '* [確定]をクリックすると,子アカウントは完全に削除され,復元できなくなります；<br/>* 削除後,子アカウントのハッシュレート曲線や収入記録などのデータは復元できなくなります。',
      deleteSuccess: '子アカウント削除成功',
    },
    footer: {
      services: 'サービス',
      Products: 'CloverPool商品',
      Help: 'ヘルプセンター',
      btcTool: 'BTCツール',
      btcSmartAgent: 'スマートエージェント',
      btcComFirmware: 'CloverPoolファームウェア',
      blockchainExplorer: 'ブラウザ',
      app: 'アプリ',
      apiDocs: 'APIドキュメント',
      vipApplication: 'VIP申請',
      cloudMinePoolService: 'クラウドマイニングプール作成サービス',
      miningTutorial: 'マイニングガイド',
      FAQ: 'よくある質問',
      Announcements: '告知',
      ticketSystem: 'チケットシステム',
    },
    pageTitle: {
      home: 'CloverPool - 世界有数の総合的なマルチ通貨マイニングプール',
      dashboard: 'ダッシュボード - CloverPool',
      miners: 'マイニングマシン管理 - CloverPool',
      earningHistory: '収益履歴 - CloverPool',
      earningHistoryForInvite: 'リベート収入 - CloverPool',
      poolStats: 'プールデータ - CloverPool',
      guardian: '保護者招待リンク - CloverPool',
      guardianBind: '保護者招待リンクを連携 - CloverPool',
      subAccount: '子アカウント作成 - CloverPool',
      subAccountManage: '子アカウント管理 - CloverPool',
      settings: '設定 - CloverPool',
      getCoin: 'コインマイニング - CloverPool',
      tools: 'ツールソフトウェア - CloverPool',
      bitdeer: 'Bitdeer - CloverPool',
      watcherExpired: '観察者権限期限切れ - CloverPool',
      watcherIncorrect: '観察者リンクエラー - CloverPool',
      fundAuthorization: '資金交換規約同意 - CloverPool',
      app: 'アプリダウンロード - CloverPool',
      invite: 'Invitation Reward - CloverPool',
    },
    breadCrumbs: {
      home: 'プールホーム',
      dashboard: 'ダッシュボード',
      miners: 'マイニングマシン',
      earningHistory: '收益履歴',
      poolStats: 'プールデータ',
      shareAccount: 'シェアアカウント設定',
      guardianBindingInvitation: '保護者招待リンク連携',
      guardianBindingLink: '保護者リンク連携',
      subAccountManage: '子アカウント管理 ',
      hiddenSubAccounts: '隠れ子アカウント管理',
      settings: '設定',
      getCoin: 'コインマイニング',
      tools: 'ツールソフトウェア',
      fundAuthorization: '資金交換規約同意',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: 'スマートプール',
      otcNotice: `「スマートプールモード」に切り替えようとしています。スマートプールモードでは,同一種類のコインでのマルチアドレス収集はサポートされていません。[確定]をクリックすると読んで同意したものとみなされます。{fundLink}`,
      fundLink: `《CloverPoolマイニングプールサービス規約》`,
      autoTip: `スマートプールモードを使用することは,CloverPoolにハッシュレートの管理を委託することを意味し,プールは,収益性を最優先するという原則に基づいて,BTC,BCH,およびBSVの間で切り替わります。。`,
      originalTip:
        '元のモードに切り替えると,SHA256ハッシュレートは選択した一つ通貨をマイニングし,自動的には切り替わりません。',
    },

    userCenter: 'ユーザーセンター',
    SignOut: 'サインアウト',

    operation: {
      name: '操作',
      success: '操作成功!',
      failed: '操作失败!',
    },
    earning: {
      balanceTip:
        '決済が完了した後、CloverPool決済センターは、次の状況を除いて、通常24時間以内に支払いを完了します。<br>1.アドレスが設定されていません。<br>2.住所は設定されていますが、累積決済金額が最低支払基準額に達していません。<br>3.住所の追加、住所の変更、住所の削除、比率の変更などの操作により、48時間の支払い停止ルールがトリガーされました。<br>4.プラットフォームのリスク管理ルールをトリガーしました<br>5.デジタル通貨は、ハードフォーク、51％の攻撃、またはその他の主要なアップグレードや事故に見舞われます。',
    },
    meta: {
      keywords:
        'ビットコイン,プール,マイニング,BTC,btc.top,bsv,eth,etc,dcr,ltc,grin,beam,slushpool,アリプール,via,viabtc,f2pool,antpool,bw,pool',
      description:
        'CloverPool は、ビットコイン、ライトコイン、ビットコイン キャッシュ、Kaspa、その他の暗号通貨のマイニングをサポートする、マイナーにとってまったく新しい選択肢です。ユーザー エクスペリエンスが大幅に向上し、手数料が大幅に下がり、サービスが強化されます。',
    },
    gotIt: '了解しました',
    foldMe: '全て折り畳む',
    showAll: '全て表示',
    copied: 'コピー成功',
    more: 'もっと見る',
    none: '無し',
    on: 'オン',
    off: 'オフ',
    all: '全部',
    modify: '修正',
    submit: '提出',
    ok: '確認',
    cancel: '取消',
    new: 'NEW',
    address: 'アドレス',
    notFoundTip: 'お探しのページは存在しません',
    export: '書き出す',
    startDate: '開始日',
    endDate: '終了日',
    smartPool: 'スマートプール',
    status: 'ステータス',
    notice: '注意',
    yes: 'はい',
    no: 'いいえ',
    coin: 'コイン',
    custom: 'カスタム',
    hide: '隠す',
    less: '少なく表示',
    details: '詳細',
    region: {
      cn: '北京（中国）',
      sz: '深圳（中国）',
      us: 'アメリカ',
      eu: 'ヨーロッパ',
      sg: 'シンガポール',
    },
    LegalStatement:
      '当人による確認と表明。私はキューバ,イラン,北朝鮮,シリア,ロシア,または関連する国,政府,国際機関によって管理または施行される制裁の対象となる国または地域の居住者ではなく,中国本土地区の居住者でもありません。 CloverPoolが提供するプールサービスは,私が所在する国または地域の法律,規制,および関連する政策に準拠しています。',
    LegalStatementConfirm:
      '私の国または地域でCloverPoolが提供するサービスの違法な利用によって引き起こされるすべての法的リスクと責任は,私が単独で負担します。',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
